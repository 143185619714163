import React from "react";
import { Image as Imagge } from '../types/Image';
import { Spinner } from "./Spinner";

export const ImageSourcePreview = ({images, maxHeight}: {images: Imagge[], maxHeight?: number}) => {
    const anyImages = images.length > 0 && images.some(i => i.src);
    const init = images.find(i => i.sort === 1) as Imagge;
    const [current, setCurrent] = React.useState<Imagge | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        setCurrent(init);
    }, [images, init]);

    React.useEffect(() => {
        const loadImage = (image: Imagge) => {
            return new Promise((resolve, reject) => {
                const loadImg = new Image()
                loadImg.src = image.src
                loadImg.onload = () => resolve(image.src);
                loadImg.onerror = err => reject(err);
            });
        }
      
        Promise.all(images.map(image => loadImage(image)))
            .then(() => setLoading(false))
            .catch(err => console.log("Failed to load images", err));
    }, [images, loading]);

    const handleClick = React.useCallback((sort: number) => {
        setCurrent(images.find(i => i.sort === sort) as Imagge);
    }, [images]);

    return(loading ? <Spinner /> :
        <div className="d-flex flex-column justify-content-center">
            {anyImages && current
                ? <>
                    <img src={current.src} className="img-fluid" alt={current.alt} style={{maxHeight: maxHeight || 500}} />
                    <div className="mt-2">
                        {images.map(i => <img key={`preview-image-${i.id}`} className="my-1" src={i.src} alt={i.alt} width='100' onClick={() => handleClick(i.sort)} />)}
                    </div>
                </>
                : <div className="img-placeholder img-placeholder-resize"><h3 className="text-white">G</h3></div>}          
        </div>);
}
import { useParams } from "react-router-dom";
import { Spinner } from "../../components/Spinner";
import { CompositionForm } from "../../components/CompositionForm";
import { useCompositionContext } from "../../hooks/composition/useCompositionContext";
import { CompositionContext } from "../../contexts/CompositionContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const CompositionDetails = () => {    
    const { id } = useParams<{id: string}>();
    const context = useCompositionContext(id);

    return(context.loading ? <Spinner /> :
        <CompositionContext.Provider value={context}>
            <DndProvider backend={HTML5Backend}>
                <CompositionForm />
            </DndProvider>            
        </CompositionContext.Provider>);
}
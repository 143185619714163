import React from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { Spinner } from "../../components/Spinner";
import { dashboardService } from "../../features/dashboard/dashboardService";

export const Dashboard = () => {
    const [loading, setLoading] = React.useState(true);
    const dispatch = useAppDispatch();
    const [aggregate, setAggregate] = React.useState({ordersCount: 0, invoicesCount: 0, compositionsCount: 0, guestsCount: 0, messagesCount: 0});

    React.useEffect(() => {
        (async () => {
            const result = await dashboardService.aggregate(dispatch);
            if (result && result.length > 0) {
                setAggregate(result[0]);
            }
            setLoading(false);
        })();
        // eslint-disable-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return(loading ? <Spinner /> : <div>
        <div className="d-flex m-5">
            {/* <div className="d-flex m-3 p-3 align-items-center bg-white rounded shadow w-100">
                <i className="bi bi-receipt-cutoff fs-1 my-4 ms-auto" />
                <div className="d-flex flex-column mx-auto">
                    <span className="text-muted">ORDERS</span>
                    <Link to="/admin/orders" className="ms-auto text-decoration-none"><h2>{aggregate.ordersCount || 0}</h2></Link>
                </div>
            </div>
            <div className="d-flex m-3 p-3 align-items-center bg-white rounded shadow w-100">
                <i className="bi bi-receipt fs-1 my-4 ms-auto" />
                <div className="d-flex flex-column mx-auto">
                    <span className="text-muted">INVOICES</span>
                    <Link to="/admin/invoices" className="ms-auto text-decoration-none"><h2>{aggregate.invoicesCount || 0}</h2></Link>
                </div>
            </div> */}
            <div className="d-flex m-3 p-3 align-items-center bg-white rounded shadow w-lg-25">
                <i className="bi bi-easel fs-1 my-4 mx-2 ms-auto" />
                <div className="d-flex flex-column mx-auto">
                    <span className="text-muted">COMPOSITIONS</span>
                    <Link to="/compositions" className="ms-auto text-decoration-none"><h2>{aggregate.compositionsCount || 0}</h2></Link>
                </div>
            </div>
            {/* <div className="d-flex m-3 p-3 align-items-center bg-white rounded shadow w-100">
                <i className="bi bi-person-check fs-1 my-4 ms-auto" />
                <div className="d-flex flex-column mx-auto">
                    <span className="text-muted">SUBSCRIBERS</span>
                    <Link to="/admin/guests" className="ms-auto text-decoration-none"><h2 className="ms-auto">{aggregate.guestsCount || 0}</h2></Link>
                </div>
            </div>
            <div className="d-flex m-3 p-3 align-items-center bg-white rounded shadow w-100">
                <i className="bi bi-envelope fs-1 my-4 ms-auto" />
                <div className="d-flex flex-column mx-auto">
                    <span className="text-muted">MESSAGES</span>
                    <h2 className="ms-auto">{aggregate.messagesCount || 0}</h2>
                </div>
            </div> */}
        </div>
    </div>);
}
import React from "react";
import { Artist } from "../types/Artist";
import { ValidationResult } from "../types/ValidationResult";

export interface ArtistContextType {
    artist: Artist;
    loading: boolean;
    disabled: boolean;
    validationResult: ValidationResult<Artist>;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onCheckedChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onValidate: (artist?: Artist) => boolean;
    onSubmit: (artist: Artist) => Promise<void>;
    onFake?: () => void;
}

export const ArtistContext = React.createContext<ArtistContextType>({} as ArtistContextType);
import axios from "axios";
import { toast } from "react-toastify";
import { narrowError } from "../../utils/errorUtils";
import { getStorageItem } from "../../utils/storageUtils";
import { User } from "../../types/User";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import { Guest } from "../../types/Guest";
import { Message } from "../../types/Message";

const API_URL = `${process.env.REACT_APP_API_DOMAIN}/api/guests`;
axios.interceptors.request.use((config) => {
    const token = getStorageItem<User>('user')?.token;
    config.headers['Content-Type'] = 'application/json';
    config.headers["X-Auth-Token"] = token;
    config.headers["Ngrok-Skip-Browser-Warning"] = true;
    return config;
});

const getAll = async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<Guest[] | undefined> => {
    try {
        const data = await axios
            .get(`${API_URL}`)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not get guests', message);
    }   
}

const add = async (guest: Guest) => {
    try {
        const { data } = await axios.post(`${API_URL}`, guest);
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not add guest', message);
    }
}

const update = async (guest: Partial<Guest>, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<Guest | undefined> => {    
    try {
        const data: Guest = await axios.put(`${API_URL}/${guest.id}`, guest)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });

        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not update guest', message);
    }
}

const remove = async (id: string, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<Guest | undefined> => {
    try {
        const data = await axios.delete(`${API_URL}/${id}`)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });

        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not remove guest', message);
    } 
}

const send = async (message: Message) => {
    try {
        const { data } = await axios.post(`${API_URL}/send`, message);
        return data;
    } catch (error) {
        const message = narrowError(error);
        toast.error(message);
        console.error('Could send message', message);
    }
}

export const guestService = { add, getAll, update, remove, send };
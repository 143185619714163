import React from "react";
import { BucketFile, Composition, Location } from "../types/Composition";
import { ImageSourcePreview } from "./ImagePreview";
import { ValidationResult } from "../types/ValidationResult";
import { Dev } from "./Dev";
import { useAppSelector } from "../hooks";
import { Role } from "../types/User";
import { formatDateString, formatInputDate } from "../utils/formatUtils";
import { EllipsisDropdown } from "./EllipsisDropdown";
import { CompositionContext, CompositionContextType } from "../contexts/CompositionContext";
import { ImageListSortable } from "./ImageListSortable";

// Represents each tab of the form
enum CompositionDataCategory {
    Details = 'Details',
    Images = 'Images',
    Financial = 'Financial',
    Provenance = 'Provenance',
    Locations = 'Locations'
}

export const CompositionForm = () => {
    const {user} = useAppSelector(state => state.auth);
    const {composition, disabled, validationResult, imageFiles, imageFilesValidationResult, imageFileInputRef, financialFile, financialFileValidationResult, financialFileInputRef, artistOptions, onChange, onCheckedChange, onImageFileChange, onImageUpload, onFinancialFileChange, onFinancialUpload, onPreviewFinancialFile, onRemoveFinancialFile, onSoldDateChange, onAddLocation, onRemoveLocation, onLocationChange, onCurrentLocationChange, onLocationDateChange, onArtistChange, onValidate, onSubmit, onFake} = React.useContext<CompositionContextType>(CompositionContext);
    const {id, clientId, stockNumber, artist, title, medium, images, financialFiles, price, width, height, year, isActive, isUnique, provenance, exhibitions, soldDate, locations} = composition;
    const initLocation = {name: '', detail: '', date: new Date(), notes: '', isCurrent: false, sort: 1} as Location;
    const [selectedLocationSort, setSelectedLocationSort] = React.useState<number>(locations.find(l => l.isCurrent)?.sort || 1);
    const nextImageSort = images.length >= 1 ? Math.max(...images.map(o => o.sort)) + 1 : 1; // if no images have been uploaded upload the next image with sort 1

    const handleSubmit = React.useCallback(async (composition?: Composition) => {
        const valid = onValidate(composition); // need to know if valid immediately
        
        if (valid) {
            await onSubmit(composition!);
        }
    }, [onSubmit, onValidate]);

    const compositionLocation = React.useCallback((sort: number) => composition?.locations?.find(x => x.sort === sort), [composition?.locations]);
    const handleSelectLocation = (sort: number) => {
        setSelectedLocationSort(sort);
    }

    const handleAddLocation = React.useCallback(() => {
        onAddLocation();
        setSelectedLocationSort(locations.length + 1);
    }, [locations, onAddLocation]);

    const handleRemoveLocation = React.useCallback((sort: number) => {        
        onRemoveLocation(sort);
        setSelectedLocationSort(1);
    }, [onRemoveLocation]);

    const anyDetailsErrors = !!validationResult.errors.stockNumber || !!validationResult.errors.artist || !!validationResult.errors.title 
                                || !!validationResult.errors.year || !!validationResult.errors.medium
                                || !!validationResult.errors.height || !!validationResult.errors.width;
    const anyImagesErrors = false;
    const anyFinancialErrors = !!validationResult.errors?.price;
    const anyProvenanceErrors = false;
    const anyLocationsErrors = validationResult.errors?.locations?.length > 0 && (validationResult.errors.locations.some(l => !Object.entries(l.errors).every(e => !e[1]))
                                || locations.some(l => l.date?.toString() === 'Invalid Date'));

    return(
        <div className="d-flex flex-column flex-lg-row bg-white rounded shadow p-3">
            <div className="d-flex w-lg-50 justify-content-center p-2">
                <ImageSourcePreview images={images} />
            </div>
            <div className="d-flex flex-column w-lg-50 p-2">
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                        <button className="nav-link active" id={`${CompositionDataCategory.Details.toLowerCase()}-tab`} data-bs-toggle="tab" data-bs-target={`#${CompositionDataCategory.Details.toLowerCase()}`} type="button" role="tab">{CompositionDataCategory.Details}{anyDetailsErrors && <i className="bi bi-exclamation-circle-fill text-danger mx-1" />}</button>
                    </li>
                    <li className="nav-item">
                        <button className="nav-link" id={`${CompositionDataCategory.Images.toLowerCase()}-tab`} data-bs-toggle="tab" data-bs-target={`#${CompositionDataCategory.Images.toLowerCase()}`} type="button" role="tab">{CompositionDataCategory.Images}{anyImagesErrors && <i className="bi bi-exclamation-circle-fill text-danger mx-1" />}</button>
                    </li>
                    <li className="nav-item">
                        <button className="nav-link" id={`${CompositionDataCategory.Financial.toLowerCase()}-tab`} data-bs-toggle="tab" data-bs-target={`#${CompositionDataCategory.Financial.toLowerCase()}`} type="button" role="tab">{CompositionDataCategory.Financial}{anyFinancialErrors && <i className="bi bi-exclamation-circle-fill text-danger mx-1" />}</button>
                    </li>
                    <li className="nav-item">
                        <button className="nav-link" id={`${CompositionDataCategory.Provenance.toLowerCase()}-tab`} data-bs-toggle="tab" data-bs-target={`#${CompositionDataCategory.Provenance.toLowerCase()}`} type="button" role="tab">{CompositionDataCategory.Provenance}{anyProvenanceErrors && <i className="bi bi-exclamation-circle-fill text-danger mx-1" />}</button>
                    </li>
                    <li className="nav-item">
                        <button className="nav-link" id={`${CompositionDataCategory.Locations.toLowerCase()}-tab`} data-bs-toggle="tab" data-bs-target={`#${CompositionDataCategory.Locations.toLowerCase()}`} type="button" role="tab">{CompositionDataCategory.Locations}{anyLocationsErrors && <i className="bi bi-exclamation-circle-fill text-danger mx-1" />}</button>
                    </li>
                </ul>
                <div className="tab-content form-lg-height">
                    <div className="tab-pane active py-2" id={CompositionDataCategory.Details.toLowerCase()}>
                        <div className="d-flex align-items-center mb-3">
                            {user?.role === Role.Super && <div className="w-50">
                                <small className="form-label">Client</small>
                                <input type="text" name="clientId" disabled className="form-control form-control-sm" value={clientId} />
                            </div>}                    
                        </div>
                        {id && <div className="d-flex mb-3">
                            <div className="w-50 me-2">
                                <small className="form-label">Stock Number</small>
                                <input type="text" name="stockNumber" className={`form-control form-control-sm ${validationResult.errors.stockNumber ? 'is-invalid' : null}`} value={stockNumber} disabled />
                                <small className="text-danger">{validationResult.errors.stockNumber}</small>
                            </div>
                        </div>}
                        <div className="d-flex mb-3">
                            <div className="w-50 me-2">
                                <small className="form-label">Artist</small>
                                <select className={`form-select form-select-sm ${validationResult.errors.artist ? 'is-invalid' : null}`} name="artist" value={artist?.id || 'DEFAULT'} onChange={onArtistChange} disabled={!!id}>
                                    <option className="d-none" value="DEFAULT" disabled>Select one...</option>
                                    {artistOptions?.map(a => <option key={a.id} value={a.id}>{a.first} {a.last}</option>)}
                                </select>
                                <small className="text-danger">{validationResult.errors.artist}</small>
                            </div>
                            <div className="form-check form-switch ms-auto mt-auto">
                                <input className="form-check-input" name="isActive" type="checkbox" checked={isActive} onChange={onCheckedChange} />
                                <label className="form-check-label">Active</label>
                            </div>
                        </div>
                        <div className="d-flex mb-3">
                            <div className="w-50 me-2">
                                <small className="form-label">Title</small>
                                <input type="text" name="title" className={`form-control form-control-sm ${validationResult.errors.title ? 'is-invalid' : null}`} value={title} onChange={onChange} />
                                <small className="text-danger">{validationResult.errors.title}</small>
                            </div>
                            <div className="w-50">
                                <small className="form-label">Year</small>
                                <input type="text" name="year" className={`form-control form-control-sm ${validationResult.errors.year ? 'is-invalid' : null}`} value={year} onChange={onChange} />
                                <small className="text-danger">{validationResult.errors.year}</small>
                            </div>
                        </div>
                        <div className="d-flex mb-3">
                            <div className="w-100">
                                <small className="form-label">Medium</small>
                                <input type="text" name="medium" className={`form-control form-control-sm ${validationResult.errors.medium ? 'is-invalid' : null}`} value={medium} onChange={onChange} />
                                <small className="text-danger">{validationResult.errors.medium}</small>
                            </div>
                        </div>
                        <div className="d-flex mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" name="isUnique" type="checkbox" checked={isUnique} onChange={onCheckedChange} />
                                <label className="form-check-label">Unique</label>
                            </div>
                        </div>
                        <div className="d-flex mb-3">
                            <div className="w-25 me-2">
                                <small className="form-label">Width (in)</small>
                                <input type="text" name="width" className={`form-control form-control-sm ${validationResult.errors.width ? 'is-invalid' : null}`} value={width} onChange={onChange} />
                                <small className="text-danger">{validationResult.errors.width}</small>
                            </div>
                            <div className="w-25 me-2">
                                <small className="form-label">Height (in)</small>
                                <input type="text" name="height" className={`form-control form-control-sm ${validationResult.errors.height ? 'is-invalid' : null}`} value={height} onChange={onChange} />
                                <small className="text-danger">{validationResult.errors.height}</small>
                            </div>
                            <div className="w-25 me-2">
                                <small className="form-label">Width (cm)</small>
                                <input type="text" disabled className='form-control form-control-sm' value={width * 2.54} />
                            </div>
                            <div className="w-25 me-2">
                                <small className="form-label">Height (cm)</small>
                                <input type="text" disabled className='form-control form-control-sm' value={height * 2.54} />
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane py-2" id={CompositionDataCategory.Images.toLowerCase()}>
                        <UploadImagesFile files={imageFiles} filesValidationResult={imageFilesValidationResult} fileInputRef={imageFileInputRef} sort={nextImageSort} onFileChange={onImageFileChange} onFileUpload={onImageUpload} />
                        {images?.length > 0 && 
                            <>
                                <div className="d-flex">
                                    <div className="w-75 me-3">
                                        <small className="form-label">Source URL</small>
                                    </div>
                                    <div className="w-25">
                                        <small className="form-label">Alt Text</small>
                                    </div>
                                </div>
                                <ImageListSortable images={images} />                                
                            </>}
                    </div>
                    <div className="tab-pane py-2" id={CompositionDataCategory.Financial.toLowerCase()}>
                        <div className="d-flex mb-3">
                            <div className="w-50 me-2">
                                <small className="form-label">Price</small>
                                <div className="input-group input-group-sm">
                                    <span className="input-group-text">$</span>
                                    <input type="text" className={`rounded-end form-control form-control-sm ${validationResult.errors.price ? 'is-invalid' : null}`} name="price" value={price} onChange={onChange} />
                                </div>
                                <small className="text-danger">{validationResult.errors.price}</small>
                            </div>
                            <div className="w-50 me-2">
                                <small className="form-label">Sold Date</small>
                                <input className='form-control form-control-sm' type="date" onChange={onSoldDateChange} name="soldDate" value={soldDate ? formatInputDate(new Date(soldDate)) : ''} />
                            </div>
                        </div>
                        <UploadFinancialFile file={financialFile} fileValidationResult={financialFileValidationResult} fileInputRef={financialFileInputRef} onFileChange={onFinancialFileChange} onFileUpload={onFinancialUpload} />
                        <FinancialFiles files={financialFiles} onPreviewFile={onPreviewFinancialFile} onRemoveFile={onRemoveFinancialFile} />
                    </div>
                    <div className="tab-pane py-2" id={CompositionDataCategory.Provenance.toLowerCase()}>
                        <div className="d-flex mb-3">
                            <div className="w-100">
                                <small className="form-label">Provenance</small>
                                <textarea name="provenance" className={`form-control form-control-sm ${validationResult.errors.provenance ? 'is-invalid' : null}`} rows={3} value={provenance} onChange={onChange} />
                                <small className="text-danger">{validationResult.errors.provenance}</small>
                            </div>
                        </div>
                        <div className="d-flex mb-3">
                            <div className="w-100">
                                <small className="form-label">Exhibitions</small>
                                <textarea name="exhibitions" className={`form-control form-control-sm ${validationResult.errors.exhibitions ? 'is-invalid' : null}`} rows={3} value={exhibitions} onChange={onChange} />
                                <small className="text-danger">{validationResult.errors.exhibitions}</small>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane py-2" id={CompositionDataCategory.Locations.toLowerCase()}>
                        <div className="d-flex align-items-center">
                            <button className="btn btn-primary btn-sm ms-auto" onClick={handleAddLocation}><i className="bi bi-plus" /></button>
                        </div>
                        <LocationForm location={compositionLocation(selectedLocationSort) || initLocation} validationResult={validationResult.errors?.locations?.find(l => l.sort === selectedLocationSort) as ValidationResult<Location>} onLocationChange={onLocationChange} onCurrentLocationChange={onCurrentLocationChange} onLocationDateChange={onLocationDateChange} disabled={locations.length === 0} />
                        {locations?.length > 0 && locations.some(l => l.name || l.detail || l.date) ? // at least one and not empty
                            <>
                                <small className="form-label">Location History</small>
                                <table className="table table-hover table-sm align-middle">
                                    <tbody>
                                        {locations.map(l => {
                                            const vr = validationResult.errors?.locations?.find(liv => liv.sort === l.sort);
                                            const validResult = Object.entries(vr?.errors || {}).every(e => !e[1]); // checking vr?.errors vs vr?.valid cuz at this point each location is validated individually and does not track 
                                            const activeRowClass = l.sort === selectedLocationSort ? 'table-active' : '';
                                            const dateInvalid = l.date?.toString() === 'Invalid Date';
                                            const errorRowClass = validResult === false || dateInvalid ? 'table-danger' : '';
                                            
                                            return <tr key={`table-location-${l.sort}`} className={activeRowClass + ' ' + errorRowClass} onClick={() => handleSelectLocation(l.sort)}>
                                                <td>{validResult === false || dateInvalid ? <i className="bi bi-exclamation-circle-fill text-danger" /> : null}</td>
                                                <td>{!dateInvalid && formatDateString(l.date as Date)}</td>
                                                <td className="w-50">{l.name}</td>
                                                <td className="w-50">{l.detail}</td>
                                                <td className="text-end text-muted">{l.isCurrent ? 'Current' : ''}</td>
                                                <td>
                                                    <EllipsisDropdown>
                                                        <ul className="dropdown-menu">
                                                            <li><button className="btn btn-sm dropdown-item" onMouseDown={() => handleRemoveLocation(l.sort)}>Remove</button></li>
                                                        </ul>
                                                    </EllipsisDropdown>
                                                </td>
                                            </tr>})}
                                    </tbody>
                                </table>
                            </>
                            // Error: 'At least one location is required'
                            : <small className="text-danger">{validationResult.errors.locations?.length > 0 && validationResult.errors.locations[0].errors.name}</small>}
                    </div>
                </div>
                <div className="d-flex align-items-center mt-auto">
                    {!id && onFake && <Dev className="d-flex align-items-center"><button onClick={() => onFake(clientId)}>Use Fake Data</button></Dev>}
                    <button className="btn btn-primary ms-auto" disabled={disabled} onClick={() => handleSubmit(composition)}>Submit</button>
                </div>
            </div>
        </div>
    );
}

interface LocationFormProps {
    location: Location;
    validationResult: ValidationResult<Location>;
    disabled: boolean;
    onLocationChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onCurrentLocationChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onLocationDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const LocationForm = ({location, validationResult, disabled, onLocationChange, onCurrentLocationChange, onLocationDateChange}: LocationFormProps) => {
    const {name, detail, date, notes, isCurrent, sort} = location;
    const dateInvalid = date?.toString() === 'Invalid Date';

    return(<div className="d-flex flex-column">
        <div className="d-flex mb-3">
            <div className="w-50">
                <small className="form-label">Location Name</small>
                <input type="text" name="name" data-sort={sort} className={`form-control form-control-sm ${validationResult?.errors?.name ? 'is-invalid' : null}`} value={name} onChange={onLocationChange} disabled={disabled} />
                <small className="text-danger">{validationResult?.errors?.name}</small>
            </div>
            <div className="form-check form-switch ms-auto mt-auto">
                <input className="form-check-input" name="isCurrent" type="checkbox" data-sort={sort} checked={isCurrent} onChange={onCurrentLocationChange} disabled={disabled} />
                <label className="form-check-label">Current Location</label>
            </div>
        </div>
        <div className="d-flex mb-3">
            <div className="w-50">
                <small className="form-label">Location Detail</small>
                <input type="text" name="detail" data-sort={sort} className={`form-control form-control-sm ${validationResult?.errors?.detail ? 'is-invalid' : null}`} value={detail} onChange={onLocationChange} disabled={disabled} />
                <small className="text-danger">{validationResult?.errors?.detail}</small>
            </div>
        </div>
        <div className="d-flex mb-3">
            <div className="w-50">
                <small className="form-label">Date</small>
                <input type="date" name="date" data-sort={sort} className={`form-control form-control-sm ${validationResult?.errors?.date || dateInvalid ? 'is-invalid' : null}`} value={date ? formatInputDate(new Date(date)) : ''} onChange={onLocationDateChange} disabled={disabled} />
                <small className="text-danger">{validationResult?.errors?.date}</small>
            </div>
        </div>
        <div className="d-flex mb-3">
            <div className="w-100">
                <small className="form-label">Location Notes</small>
                <textarea name="notes" data-sort={sort} className={`form-control form-control-sm ${validationResult?.errors?.notes ? 'is-invalid' : null}`} rows={3} value={notes} onChange={onLocationChange} disabled={disabled} />
                <small className="text-danger">{validationResult?.errors?.notes}</small>
            </div>
        </div>
    </div>);
}

interface UploadImageFileProps {
    files: File[] | undefined;
    filesValidationResult: ValidationResult<Pick<File, 'name'>>;
    fileInputRef: React.RefObject<HTMLInputElement>;
    sort: number;
    onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFileUpload: (sort: number) => void;
}

const UploadImagesFile = ({files, filesValidationResult, fileInputRef, sort, onFileChange, onFileUpload}: UploadImageFileProps) => {
    return(
        <div className="d-flex flex-column mb-3">
            <small className="form-label">Upload File</small>
            <input className={`form-control form-control-sm ${filesValidationResult.errors.name ? 'is-invalid' : null}`} name="files" type="file" ref={fileInputRef} multiple onChange={onFileChange} />
            <small className="text-danger mb-2">{filesValidationResult?.errors.name}</small>
            <div className="d-flex align-items-center mt-auto">
                <button className="btn btn-primary ms-auto" type="submit" disabled={!files || files.length === 0 || !filesValidationResult.valid} onClick={() => onFileUpload(sort)}>Upload</button>
            </div>
        </div>);
}

interface UploadFinancialFileProps {
    file: File | undefined;
    fileValidationResult: ValidationResult<Pick<File, 'name'>>;
    fileInputRef: React.RefObject<HTMLInputElement>;
    onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFileUpload: () => void;
}

const UploadFinancialFile = ({file, fileValidationResult, fileInputRef, onFileChange, onFileUpload}: UploadFinancialFileProps) => {
    return(
        <div className="d-flex flex-column mb-3">
            <small className="form-label">Upload File</small>
            <input className={`form-control form-control-sm ${fileValidationResult.errors.name ? 'is-invalid' : null}`} name="file" type="file" ref={fileInputRef} onChange={onFileChange} />
            <small className="text-danger mb-2">{fileValidationResult?.errors.name}</small>
            <div className="d-flex align-items-center mt-auto">
                <button className="btn btn-primary ms-auto" type="submit" disabled={!file || !fileValidationResult.valid} onClick={() => onFileUpload()}>Upload</button>
            </div>
        </div>);
}

const FinancialFiles = ({files, onPreviewFile, onRemoveFile}: {files: BucketFile[], onPreviewFile: (name: string, type: string) => void, onRemoveFile: (name: string) => void}) => {    
    return(<>        
        {files?.length > 0 && 
            <div className="d-flex flex-column mb-3">
                <table className="table table-hover table-sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th>File Name</th>
                            <th className="text-center">Upload Date</th>
                            <th className="text-center">Size</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {files.sort((a,b) => Number(new Date(b.lastModified || 0)) - Number(new Date(a.lastModified || 0))).map((file, i) => 
                            <tr key={`${file.name}-${i}`} className="align-middle">
                                <td className="fs-5 text-center"><button className="btn btn-outline-dark btn-sm border-0 ms-1" onClick={() => onPreviewFile(file.name, file.type!)}>{fileTypeIcon(file.type)}</button></td>
                                <td className="w-50">{file.name}</td>
                                <td className="text-center">{file.lastModified ? formatDateString(file.lastModified) : 'Unknown'}</td>
                                <td className="text-center">{humanFileSize(file.size || 0)}</td>
                                <td><button className="btn btn-outline-danger btn-sm border-0 ms-1" onClick={() => onRemoveFile(file.name)}><i className="bi-x" /></button></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>}
    </>);
}

const fileTypeIcon = (contentType: string | undefined): JSX.Element => {
    let icon: JSX.Element;
    switch (contentType) {
        case 'application/pdf':
            icon = <i className="bi bi-file-earmark-pdf" />;
            break;
        case 'image/gif':
        case 'image/jpeg':
        case 'image/png':
            icon = <i className="bi bi-file-earmark-image" />;
            break;
        default:
            icon = <i className="bi bi-file-earmark" />
            break;
    }

    return icon;
}

const humanFileSize = (bytes: number, si=false, dp=1) => {
    const thresh = si ? 1000 : 1024;
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
  
    return bytes.toFixed(dp) + ' ' + units[u];
}
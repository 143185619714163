import { Invoice, Status, calculate } from "../types/Invoice";
import { randomTestAddress } from "./addresses";

const invoice1: Invoice = {
    id: '',
    date: new Date(),
    number: '',
    to: {id: '', name: 'Tappan Collective', first: 'Winthorp', last: 'Tappan', ...randomTestAddress(), phone: '213-555-1234', email: 'winthorp@thetappancollective.com', website: 'thetappancollective.com', isActive: true},
    from: {id: '', name: 'Alyssa Breid Art', first: 'Alyssa', last: 'Breid', ...randomTestAddress(), phone: '323-555-0987', email: 'hello@alyssabreid.com', website: 'alyssabreid.com', isActive: true},
    lineItems: [
        {
            sort: 1,
            name: 'Composition commission',
            description: '50% of $8000 commission for \'Plenilunium\' composition',
            price: 4000,
            quantity: 1,
            metadata: {key1: 'value1'},
            id: '',
            stripePriceId: ''
        },
        {
            sort: 2,
            name: 'Shipping materials',
            description: 'Shipping case for \'Plenilunium\' composition',
            price: 250,
            quantity: 1,
            metadata: {key2: 'value2'},
            id: '',
            stripePriceId: ''
        }
    ],
    status: Status.Active,
    subtotal: 0,
    discount: 0,
    tax: 0,
    total: 0,
    balanceDue: 0,
    dueDate: null,
    notes: 'This invoice must be paid before the goods are shipped',
    stripePaymentLink: ''
}

export const testInvoices = () => {
    let invoices = [invoice1];
    invoices = invoices.map(i => {
        const {subtotal, tax, discount, total} = calculate(i.lineItems);
        return {...i, subtotal, tax, discount, total, balanceDue: total};
    });
    console.log('faked', invoices);
    return invoices;
};

export const randomTestInvoice = () => {
    const invoices = testInvoices();
    return invoices[Math.floor(Math.random() * invoices.length)];
}
import React from "react";
import { useAppSelector } from "../hooks";
import { ArtistContext, ArtistContextType } from "../contexts/ArtistContext";
import { Dev } from "./Dev";
import { Artist } from "../types/Artist";

export const ArtistForm = () => {
    const {user} = useAppSelector(state => state.auth);
    const {artist, disabled, validationResult, onChange, onCheckedChange, onValidate, onSubmit, onFake} = React.useContext<ArtistContextType>(ArtistContext);
    const {first, last, isActive} = artist;

    const handleSubmit = React.useCallback(async (artist?: Artist) => {
        const valid = onValidate(artist); // need to know if valid immediately
        
        if (valid) {
            await onSubmit(artist!);
        }
    }, [onSubmit, onValidate]);

    return(
        <div className="d-flex flex-column bg-white rounded shadow p-3">
            <div className="d-flex flex-column flex-sm-row mb-3 px-5">
                <div className="w-sm-50 me-2">
                    <small className="form-label">First Name</small>
                    <input type="text" name="first" className={`form-control form-control-sm ${validationResult.errors.first ? 'is-invalid' : null}`} value={first} onChange={onChange} />
                    <small className="text-danger">{validationResult.errors.first}</small>
                </div>
                <div className="w-sm-50 me-2">
                    <small className="form-label">Last Name</small>
                    <input type="text" name="last" className={`form-control form-control-sm ${validationResult.errors.last ? 'is-invalid' : null}`} value={last} onChange={onChange} />
                    <small className="text-danger">{validationResult.errors.last}</small>
                </div>
                <div className="form-check form-switch ms-auto mt-sm-auto mt-3">
                    <input className="form-check-input" name="isActive" type="checkbox" checked={isActive} onChange={onCheckedChange} />
                    <label className="form-check-label">Active</label>
                </div>
            </div>
            <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center mt-auto px-5">
                {onFake && <Dev className="d-flex align-items-center my-1"><button onClick={onFake}>Use Fake Data</button></Dev>}
                <button className="btn btn-primary ms-sm-auto my-1" disabled={disabled} onClick={() => handleSubmit(artist)}>Submit</button>
            </div>
        </div>
    );
}
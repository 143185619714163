import React from "react";
import { toast } from "react-toastify";
import { CompositionContextType } from "../../contexts/CompositionContext";
import { compositionService } from "../../features/compositions/compositionService";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { Composition, initComposition, validateCompositionField, validateLocationField, validateComposition } from "../../types/Composition";
import { CompositionValidationResult, ValidationResult } from "../../types/ValidationResult";
import { narrowError } from "../../utils/errorUtils";
import { Image } from "../../types/Image";
import { Artist } from "../../types/Artist";
import { artistService } from "../../features/artists/artistService";
import { validateFileField } from "../../types/ValidatedFile";

export const useCompositionContext = (id?: string): CompositionContextType => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {user} = useAppSelector(state => state.auth);
    const [artistOptions, setArtistOptions] = React.useState<Artist[]>([]);
    const [composition, setComposition] = React.useState<Composition>(initComposition(user?.clientId || ''));
    const [loading, setLoading] = React.useState(false);
    const [disabled, setDisabled] = React.useState(true);
    const [validationResult, setValidationResult] = React.useState<CompositionValidationResult>({valid: false, errors: {clientId: '', stockNumber: '', artist: '', title: '', medium: '', images: '', financialFiles: '', price: '', width: '', height: '', year: '', isUnique: '', isActive: '', provenance: '', exhibitions: '', soldDate: '', locations: []}});
    const [imageFiles, setImageFiles] = React.useState<File[]>();
    const [imageFilesValidationResult, setImageFilesValidationResult] = React.useState<ValidationResult<Pick<File, 'name'>>>({valid: false, errors: {name: ''}});
    const imageFileInputRef = React.useRef<HTMLInputElement>(null);
    const [imageFileProgressPct, setImageFileProgressPct] = React.useState(0);
    const [financialFile, setFinancialFile] = React.useState<File>();
    const [financialFileValidationResult, setFinancialFileValidationResult] = React.useState<ValidationResult<Pick<File, 'name'>>>({valid: false, errors: {name: ''}});
    const financialFileInputRef = React.useRef<HTMLInputElement>(null);
    const [financialFileProgressPct, setFinancialFileProgressPct] = React.useState(0);
    const dragImageRef = React.useRef<any>(null);
    const dragOverImageRef = React.useRef<any>(null);

    React.useEffect(() => {
        setLoading(true);
        (async () => {
            const names = await artistService.getAllNames(dispatch);
            if (names) {
                setArtistOptions(names as Artist[]);
            } else {
                toast.error('Could not find artists\' names');
            }
        })();

        if (id) {            
            (async () => {
                const resource = await compositionService.get(id, dispatch);
                if (resource) {
                    resource.locations = resource.locations.sort((a,b) => Number(new Date(b.date || 0)) - Number(new Date(a.date || 0)));
                    setComposition(resource as Composition);
                } else {
                    toast.error('Could not find composition ' + id);
                }

                setLoading(false);
            })();
        } else {
            setLoading(false);
        }
    }, [dispatch, id]);

    const onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        e.preventDefault();

        // enable on init change
        if (disabled) {
            setDisabled(false);
        }

        const { name, value } = e.target;
        
        const error = validateCompositionField({name, value});
        setValidationResult((prev: CompositionValidationResult) => ({...prev, errors: {...prev.errors, [name]: error}}));
        setComposition(prev => ({...prev, ...{[name]: value}}));
    }, [disabled]);

    const onCheckedChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        // enable on init change
        if (disabled) {
            setDisabled(false);
        }

        const { name, checked } = e.target;
        setComposition(prev => ({...prev, [name]: checked}));        
    }, [disabled]);

    const onAddImage = React.useCallback(() => {
        setComposition(prev => ({
            ...prev, 
            images: [...prev.images, {src: '', alt: '', sort: prev.images.length + 1}]
        }));
    }, []);

    const onRemoveImage = React.useCallback((sort: number) => {
        if (disabled) {
            setDisabled(false);
        }
        
        setComposition(prev => ({
            ...prev, 
            images: [...prev.images.filter(i => i.sort !== sort)]
        }));
    }, [disabled]);

    const onSortImages = React.useCallback((id: string, atIndex: number) => {
        if (disabled) {
            setDisabled(false);
        }

        // https://github.com/thebikashweb/react-drag-drop-without-library/blob/master/src/pages/ListSort.tsx
        setComposition(prev => {
            let images = [...prev.images];
            const dragImage = prev.images?.find(x => x.id === id) as Image;
            const dragImageIndex = images.indexOf(dragImage);
            images.splice(dragImageIndex, 1);                               // remove the card we're dragging
            images.splice(atIndex, 0, dragImage);                           // put the card we're hovering over in it's place
            images = images.map((im, ix) => ({...im, sort: ix + 1}));       // reassign sort values after sorting

            return {...prev, images};
        });
    }, [disabled]);

    const onSortImagesOld = React.useCallback(() => {
        if (disabled) {
            setDisabled(false);
        }

        // https://github.com/thebikashweb/react-drag-drop-without-library/blob/master/src/pages/ListSort.tsx
        setComposition(prev => {
            //remove and save the dragged item content
            const draggedItemContent = prev.images.splice(dragImageRef.current, 1)[0];

            //switch the position
            prev.images.splice(dragOverImageRef.current, 0, draggedItemContent);

            //reset the position ref
            dragImageRef.current = null;
            dragOverImageRef.current = null;

            // reassign sort values after sorting
            prev.images = prev.images.map((im, ix) => ({...im, sort: ix + 1}));

            return {...prev, images: prev.images};
        });
    }, [disabled]);

    const onImageFileChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files.length > 5) {
                toast.error('Upload up to 5 files');
                return;
            }
            
            // validate all files
            let validationResults: ValidationResult<Pick<File, 'name'>>[] = [];
            for (const file of Array.from(e.target.files)) 
            {
                const error = validateFileField({key: 'name', value: file.name});
                validationResults.push({valid: !error, errors: {name: error}});
            }

            // find first error
            if (validationResults.some(vr => !vr.valid)) {
                setImageFilesValidationResult(validationResults.find(vr => !vr.valid)!)
            } else {
                setImageFilesValidationResult({valid: true, errors: {name: ''}});
            }

            // formData only recognizes raw e.target.files
            setImageFiles(Array.from(e.target.files));
        } else {
            imageFileInputRef.current!.value = '';
            setImageFiles(undefined);
            setImageFilesValidationResult({valid: false, errors: {name: ''}});
        }
    }, []);

    const onImageUpload = React.useCallback(async (sort: number) => {
        setLoading(true);

        if (!composition.id) {
            toast.error('Save composition first');
            setLoading(false);
            return;
        }

        if (!imageFiles || imageFiles.length === 0) {
            toast.error('No file selected');
            setLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append('sort', sort.toString());
        for (const file of imageFiles) {
            formData.append('files', file);
        }
    
        try {
            const updated = await compositionService.uploadImages(composition.id!, formData, setImageFileProgressPct, dispatch);

            if (updated?.id) {
                toast.success(`Composition ${updated.id} updated`);
                setComposition(updated);
            } else {
                toast.error(updated?.message);
            }

            return updated;
        } catch (error) {
            console.log(error);
        } finally {
            setImageFiles(undefined);
            setLoading(false);
        }
    }, [composition.id, dispatch, imageFiles]);

    const onFinancialFileChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const error = validateFileField({key: 'name', value: file.name});
            // formData only recognizes raw e.target.files
            setFinancialFile(e.target.files[0]);
            setFinancialFileValidationResult({valid: !error, errors: {name: error}});
        } else {
            financialFileInputRef.current!.value = '';
            setFinancialFile(undefined);
            setFinancialFileValidationResult({valid: false, errors: {name: ''}});
        }
    }, []);

    const onFinancialUpload = React.useCallback(async () => {
        setLoading(true);

        if (!composition.id) {
            toast.error('Save composition first');
            setLoading(false);
            return;
        }

        if (!financialFile || financialFile.size === 0) {
            toast.error('No file selected');
          return;
        }

        const formData = new FormData();
        formData.append('file', financialFile, financialFile.name);
    
        try {
            const updated = await compositionService.uploadFinancial(composition.id!, formData, setFinancialFileProgressPct, dispatch);
            if (updated?.id) {
                toast.success(`Composition ${updated.id} updated`);
                setComposition(updated);
            } else {
                toast.error(updated?.message);
            }
            return updated;
        } catch (error) {
            console.log(error);
        } finally {
            setFinancialFile(undefined);
            setLoading(false);
        }
    }, [composition.id, dispatch, financialFile]);

    const onPreviewFinancialFile = React.useCallback(async (name: string, type: string) => {
        try {
            const stream = await compositionService.previewFinancial(composition.id!, name, dispatch);
            if (stream) {
                console.log(stream, [new Uint8Array(stream).buffer], type);
                const file = new Blob(
                    [new Uint8Array(stream).buffer], 
                    {type});
                //Build a URL from the file
                const fileUrl = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileUrl);
            }            
        } catch (error) {
            console.log(error);
        }
    }, [composition.id, dispatch]);

    const onRemoveFinancialFile = React.useCallback((name: string) => {
        if (disabled) {
            setDisabled(false);
        }
        
        setComposition(prev => ({
            ...prev, 
            financialFiles: [...prev.financialFiles.filter(i => i.name !== name)]
        }));
    }, [disabled]);

    const onSoldDateChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const { name, value } = e.target;
        // YYYY-mm-dd without the time specified is assumed in UTC and offsets the date by a day
        // https://stackoverflow.com/questions/9509360/datepicker-date-off-by-one-day
        const newDate = new Date(value + 'T00:00:00');

        // enable on init change
        if (disabled) {
            setDisabled(false);
        }
        console.log({[name]: newDate});
        setComposition(prev => ({
            ...prev,
            ...{[name]: newDate}
        }));
    }, [disabled]);

    const onAddLocation = React.useCallback(() => {
        if (disabled) {
            setDisabled(false);
        }
        
        setComposition(prev => ({
            ...prev, 
            locations: [...prev.locations, {name: '', detail: '', date: new Date(), notes: '', isCurrent: false, sort: prev.locations.length + 1}].sort((a,b) => Number(new Date(b.date || 0)) - Number(new Date(a.date || 0)))
        }));
    }, [disabled]);

    const onRemoveLocation = React.useCallback((sort: number) => {
        if (disabled) {
            setDisabled(false);
        }

        setComposition(prev => ({
            ...prev, 
            locations: [...prev.locations.filter(i => i.sort !== sort)]
        }));
    }, [disabled]);

    const onLocationChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        e.preventDefault();

        // enable on init change
        if (disabled) {
            setDisabled(false);
        }

        const { name, value } = e.target;
        const sort = Number(e.target.dataset.sort); // represents data-sort attr
        const error = validateLocationField({name, value});

        setValidationResult((prev: CompositionValidationResult) => ({
            ...prev, 
            errors: {
                ...prev.errors,
                locations: prev.errors.locations?.map(i => i?.sort === sort ? {...i, errors: {...i.errors, [name]: error}} : i)
            }}));

        setComposition(prev => ({
            ...prev, 
            locations: prev 
                ? prev.locations?.map(i => i?.sort === sort ? {...i, [name]: value} : i) 
                : [{[name]: value, id: '', sort, name: '', detail: '', date: null, notes: '', isCurrent: false}]
        }));
    }, [disabled]);

    const onCurrentLocationChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        // enable on init change
        if (disabled) {
            setDisabled(false);
        }

        const { name, checked } = e.target;
        const sort = Number(e.target.dataset.sort); // represents data-sort attr

        setComposition(prev => ({
            ...prev, 
            locations: prev 
                ? prev.locations?.map(i => i?.sort === sort ? {...i, [name]: checked} : {...i, [name]: false})
                : [{[name]: checked, id: '', sort, name: '', detail: '', date: null, notes: '', isCurrent: false}]
        }));
    }, [disabled]);

    const onLocationDateChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const { name, value } = e.target;
        const sort = Number(e.target.dataset.sort); // represents data-sort attr
        // YYYY-mm-dd without the time specified is assumed in UTC and offsets the date by a day
        // https://stackoverflow.com/questions/9509360/datepicker-date-off-by-one-day
        const newDate = new Date(value + 'T00:00:00');
        const error = validateLocationField({key: name, value: newDate});
        setValidationResult((prev: CompositionValidationResult) => ({
            ...prev, 
            errors: {
                ...prev.errors,
                locations: prev.errors.locations?.map(i => i?.sort === sort ? {...i, errors: {...i.errors, [name]: error}} : i)
            }}));

        // enable on init change
        if (!error && disabled) {
            setDisabled(false);
        }

        setComposition(prev => ({
            ...prev, 
            locations: prev 
                ? prev.locations?.map(i => i?.sort === sort ? {...i, [name]: newDate} : i).sort((a,b) => Number(new Date(b.date || 0)) - Number(new Date(a.date || 0)))
                : [{[name]: newDate, id: '', sort, name: '', detail: '', date: null, notes: '', isCurrent: false}]
        }));
    }, [disabled]);

    const onArtistChange = React.useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();

        // enable on init change
        if (disabled) {
            setDisabled(false);
        }

        const {name, value} = e.target;
        setComposition(prev => ({...prev, ...{[name]: artistOptions.find(o => o.id === value)}}));
    }, [artistOptions]);

    const onSubmit = React.useCallback(async (composition: Composition) => {
        if (id) {
            try {
                const updated = await compositionService.update(composition, dispatch);
                if (updated?.id) {
                    setComposition(updated);
                    toast.success(`Composition ${updated.id} updated`);
                } else {
                    toast.error('Failed to update composition');
                }
            } catch (error) {
                const message = narrowError(error);
                toast.error(message);
            }
            // dispatch(updateComposition(composition));
        } else {
            try {
                const created = await compositionService.add(composition, dispatch);
                if (created?.id) {
                    setComposition(created);
                    navigate(`/compositions/${created.id}`);
                    toast.success(`Composition ${created.id} added`);
                } else {
                    toast.error('Failed to create composition');
                }
            } catch (error) {
                const message = narrowError(error);
                toast.error(message);
            }
            // dispatch(addComposition(composition));
        }

        // to prevent submitting without changes
        if (disabled) {
            setDisabled(true);
        }
    }, [id, disabled, dispatch]);

    const onValidate = React.useCallback((composition?: Composition): boolean => {
        const {valid, errors} =  validateComposition(composition);
        setValidationResult({valid, errors});
        return valid;
    }, []);

    const onFake = React.useCallback((clientId: string) => {
        const devArtistId = process.env.REACT_APP_DEV_ARTIST_ID;
        const testComposition = {
            clientId,
            stockNumber: '',
            artist: {_id: devArtistId, first: 'Pablo', last: 'Picasso', name: '', address1: '', address2: '', city: '', state: '', zip: '', phone: '', email: '', website: '', isActive: true, compositions: []}, // DEV only
            title: 'Bootstrap Composition', 
            description: 'This is a test Bootstrap composition', 
            materials: 'Synthetic Bits',
            medium: 'Web',
            financialFiles: [],
            images: [{src: 'https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png', alt: 'bootstrap-logo-shadow', sort: 1}], 
            price: 100, 
            width: 10, 
            height: 10,
            year: 2011,
            isUnique: false, 
            isActive: true,
            provenance: '',
            exhibitions: '',
            soldDate: null,
            locations: [
                {name: 'Hannah Hoffman Gallery', detail: 'via TM', date: new Date('2023-10-01'), notes: '', isCurrent: true, sort: 1}, 
                {name: 'Art Movement LA', detail: 'via Artech', date: new Date('2023-09-01'), notes: '', isCurrent: false, sort: 2}
            ]
        };
        setComposition({...testComposition, artist: artistOptions.find(o => o.id === devArtistId)!});
        setDisabled(false);
    }, [artistOptions]);
    
    return {composition, loading, disabled, validationResult, imageFiles, imageFilesValidationResult, imageFileInputRef, financialFile, financialFileValidationResult, financialFileInputRef, dragImageRef, dragOverImageRef, artistOptions, onChange, onCheckedChange, onAddImage, onRemoveImage, onSortImages, onSortImagesOld, onImageFileChange, onImageUpload, onFinancialFileChange, onFinancialUpload, onPreviewFinancialFile, onRemoveFinancialFile, onSoldDateChange, onAddLocation, onRemoveLocation, onLocationChange, onCurrentLocationChange, onLocationDateChange, onArtistChange, onValidate, onSubmit, onFake};
}
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import React from "react";
import { Artist, validateArtist, validateArtistField } from "../../types/Artist";
import { ArtistContextType } from "../../contexts/ArtistContext";
import { ValidationResult } from "../../types/ValidationResult";
import { artistService } from "../../features/artists/artistService";
import { narrowError } from "../../utils/errorUtils";
import { toast } from "react-toastify";

export const useArtistContext = (id?: string): ArtistContextType => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {user} = useAppSelector(state => state.auth);
    const [artist, setArtist] = React.useState<Artist>({first: '', last: '', name: '', address1: '', address2: '', city: '', state: '', zip: '', phone: '', email: '', website: '', compositions: [], isActive: false});
    const [loading, setLoading] = React.useState(false);
    const [disabled, setDisabled] = React.useState(true);
    const [validationResult, setValidationResult] = React.useState<ValidationResult<Artist>>({valid: false, errors: {id: '', first: '', last: '', name: '', address1: '', address2: '', city: '', state: '', zip: '', phone: '', email: '', website: '', compositions: '', isActive: ''}});

    React.useEffect(() => {
        if (id) {
            setLoading(true);
            (async () => {
                const resource = await artistService.get(id, dispatch);
                if (resource) {
                    setArtist(resource as Artist);
                } else {
                    toast.error('Could not find artist ' + id);
                }
            })();
            setLoading(false);
        }
    }, [dispatch, id]);

    const onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        e.preventDefault();

        // enable on init change
        if (disabled) {
            setDisabled(false);
        }

        const { name, value } = e.target;
        
        const error = validateArtistField({name, value});
        setValidationResult((prev: ValidationResult<Artist>) => ({...prev, errors: {...prev.errors, [name]: error}}));
        setArtist(prev => ({...prev, ...{[name]: value}}));
    }, [disabled]);

    const onCheckedChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        // enable on init change
        if (disabled) {
            setDisabled(false);
        }

        const { name, checked } = e.target;
        setArtist(prev => ({...prev, [name]: checked}));        
    }, [disabled]);


    const onSubmit = React.useCallback(async (artist: Artist) => {
        if (id) {
            try {
                const updated = await artistService.update(artist, dispatch);
                if (updated?.id) {
                    setArtist(updated);
                    toast.success(`Artist ${updated.id} updated`);
                } else {
                    toast.error('Failed to update artist');
                }
            } catch (error) {
                const message = narrowError(error);
                toast.error(message);
            }
        } else {
            try {
                const created = await artistService.add(artist, dispatch);
                if (created?.id) {
                    setArtist(created);
                    navigate(`/artists/${created.id}`);
                    toast.success(`Artist ${created.id} added`);
                } else {
                    toast.error('Failed to create artist');
                }
            } catch (error) {
                const message = narrowError(error);
                toast.error(message);
            }
        }

        // to prevent submitting without changes
        if (disabled) {
            setDisabled(true);
        }
    }, [id, disabled, dispatch]);

    const onValidate = React.useCallback((artist?: Artist): boolean => {
        const {valid, errors} =  validateArtist(artist);
        setValidationResult({valid, errors});
        return valid;
    }, []);

    const onFake = React.useCallback(() => {
        const fake: Artist = { first: '', last: '', name: '', address1: '', address2: '', city: '', state: '', zip: '', phone: '', email: '', website: '', compositions: [], isActive: true };
        setArtist(fake);
        setDisabled(false);
    }, []);

    return {artist, loading, disabled, validationResult, onChange, onCheckedChange, onSubmit, onValidate, onFake};
}
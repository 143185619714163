import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import artistReducer from './features/artists/artistSlice';
import authReducer from './features/auth/authSlice';
import cartReducer from './features/cart/cartSlice';
import compositionReducer from './features/compositions/compositionSlice';
import guestReducer from './features/guest/guestSlice';
import orderReducer from './features/order/orderSlice';

export const store = configureStore({
  reducer: {
    artists: artistReducer,
    auth: authReducer,
    cart: cartReducer,
    compositions: compositionReducer,
    guest: guestReducer,
    order: orderReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
import React from "react";
import { Composition } from "../types/Composition";
import { CompositionValidationResult, ValidationResult } from "../types/ValidationResult";
import { Artist } from "../types/Artist";

export interface CompositionContextType {
    composition: Composition;
    loading: boolean;
    disabled: boolean;
    validationResult: CompositionValidationResult;
    imageFiles: File[] | undefined;
    imageFilesValidationResult: ValidationResult<Pick<File, 'name'>>;
    imageFileInputRef: React.RefObject<HTMLInputElement>;
    financialFile: File | undefined;
    financialFileValidationResult: ValidationResult<Pick<File, 'name'>>;
    financialFileInputRef: React.RefObject<HTMLInputElement>;
    dragImageRef: React.MutableRefObject<any>;
    dragOverImageRef: React.MutableRefObject<any>;
    artistOptions: Artist[] | undefined;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onCheckedChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onAddImage: () => void;
    onRemoveImage: (sort: number) => void;
    onSortImages: (id: string, atIndex: number) => void;
    onSortImagesOld: () => void;
    onImageFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;    
    onImageUpload: (sort: number) => void;
    onFinancialFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFinancialUpload: () => void;
    onPreviewFinancialFile: (name: string, type: string) => void,
    onRemoveFinancialFile: (name: string) => void,
    onSoldDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onAddLocation: () => void;
    onRemoveLocation: (sort: number) => void;
    onLocationChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onCurrentLocationChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onLocationDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onArtistChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onValidate: (composition?: Composition) => boolean;
    onSubmit: (composition: Composition) => Promise<void>;
    onFake?: (clientId: string) => void;
}

export const CompositionContext = React.createContext<CompositionContextType>({} as CompositionContextType);
import { capitalize } from "../utils/formatUtils";
import { ValidationResult } from "./ValidationResult";

export function validateFile(file?: Partial<File>) {
    const validationResult: ValidationResult<Pick<File, 'name'>> = {
        valid: false,
        errors: {
            name: ''
        }
    };

    if (!file || Object.entries(file).length === 0) {
        for (const key in validationResult.errors) {
            // itemize errors by key to know exactly what is missing
            (validationResult.errors as any)[key] = validateFileField({key, value: undefined});
        }    
        return validationResult;
    }

    //let key: keyof Pick<File, 'name'>;
    let key = 'name';
    const value = file ? file.name : undefined;
    validationResult.errors.name = validateFileField({key, value});   

    // no errors, validate
    if (Object.entries(validationResult.errors).every(e => !e[1])) {
        validationResult.valid = true;
    }

    return validationResult; 
}

export function validateFileField({key, value}: {[key: string]: string | null | undefined}): string {
    let error = '';
    const unsafeChars = ['&', '$', '@', '=', ';', '/', ':', '+', ' ', ',', '?', '\\', '{', '}', '^', '%', '`', '[', ']', '<', '>', '~', '#', '|'];
    
    switch (key) {
        case 'name':      
            if (!value) {
                error = `${capitalize(key as string)} is required`;
            } else if (unsafeChars.some(char => value.includes(char))) {
                error = 'One or more files contains unsafe characters (\'&\', \'#\', \'$\', \'@\', \'%\', \'=\', \'+\', \':\', \';\', \',\', \'\\\', \'/\', (space), \'?\', \'{\', \'}\', \'[\', \']\', \'^\', \'<\', \'>\', \'~\', \'`\', \'|\')';
            }
            break;
        default:
            break;
    }
    if(error) console.log('File name validation error', error);
    return error;
}
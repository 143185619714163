import React from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { Composition, validateCompositionField } from "./types/Composition";
import { toast } from "react-toastify";
import * as bootstrap from 'bootstrap';
import { Client, validateClient } from "./types/Client";
import { Image } from "./types/Image";
import { clientService } from "./features/clients/clientService";
import { InvoiceValidationResult, ValidationResult } from "./types/ValidationResult";
import { Role, User, validateUser, validateUserField } from "./types/User";
import { randomTestUser } from "./fake/users";
import { userService } from "./features/users/userService";
import { invoiceService } from "./features/invoice/invoiceService";
import { Invoice, calculate, init as initInvoice, validateInvoice, validateInvoiceField, validateLineItemField } from "./types/Invoice";
import { randomTestInvoice } from "./fake/invoices";
import { initValidationResult as initBusinessEntityValidationResult, validateBusinessEntityField } from "./types/BusinessEntity";
import { fileService } from "./features/files/fileService";
import { GridFsFile } from "./types/GridFsFile";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useUser = (id?: string): [
    User,
    boolean,
    boolean,
    ValidationResult<User & {password: string;}>,
    (e: React.ChangeEvent<HTMLInputElement>) => void, // onChange
    (user?: User & {password: string;}) => boolean, // onValidate
    (user: User & {password: string}) => Promise<void>, // onSubmit
    () => void // onFake
] => {
    const dispatch = useAppDispatch();
    const [user, setUser] = React.useState<User>({id: '', clientId: '', first: '', last: '', email: '', password: '', isActive: true, role: Role.Viewer, token: ''} as User);
    const [loading, setLoading] = React.useState(false);
    const [disabled, setDisabled] = React.useState(true);
    const [validationResult, setValidationResult] = React.useState<ValidationResult<User & {password: string;}>>({valid: false, errors: {id: '', clientId: '', first: '', last: '', email: '', isActive: '', role: '', token: '', password: ''}});

    React.useEffect(() => {
        if (id) {
            setLoading(true);
            (async () => {
                const result = await userService.get(id, dispatch);
                if (result) {
                    setUser(result as User);
                } else {
                    toast.error('Could not find user ' + id);
                }
                
            })();
            setLoading(false);
        }
    }, [dispatch, id]);

    const onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        // enable on init change
        if (disabled) {
            setDisabled(false);
        }

        const { name, value } = e.target;
        
        const error = validateUserField({name, value});
        setValidationResult((prev: ValidationResult<User & {password: string;}>) => ({...prev, errors: {...prev.errors, [name]: error}}));
        setUser(prev => ({...prev, ...{[name]: value}}));
    }, [disabled]);

    const onSubmit = React.useCallback(async (user: User & {password: string}) => {
        if (id) {
            const result = await userService.update(user, dispatch);
            if (result) {
                toast.success(`User ${result.id} updated`);
            }
        } else {
            const result = await userService.add(user, dispatch);
            if (result) {
                toast.success(`User ${result.id} added`);
            }
            
        }        
    }, [id, dispatch]);

    const onValidate = React.useCallback((user?: User & {password: string;}): boolean => {
        const {valid, errors} = validateUser(user);
        setValidationResult({valid, errors});
        if (!valid) {
            setDisabled(true);
        }

        return valid;
    }, []);

    const onFake = React.useCallback(() => {
        setUser(randomTestUser());
        setDisabled(false);
    }, []);

    return [user, loading, disabled, validationResult, onChange, onValidate, onSubmit, onFake];
}

export const useClient = (id?: string): [
    Client, //client
    boolean, // loading
    boolean, // disabled
    ValidationResult<Client>, // validationResult
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void, // onChange
    (user: User) => void, // onAddUser
    (composition: Composition) => void, // onAddComposition
    (id: string) => void, // onRemove
    (client?: Client) => boolean, // onValidate
    (client: Client) => Promise<void> // onSubmit
] => {
    const dispatch = useAppDispatch();
    const [client, setClient] = React.useState<Client>({id: '', brandName: '', name: '', first: '', last: '', address: {address1: '', address2: '', city: '', state: '', zip: ''}, phone: '', users: [], compositions: [], isActive: true} as Client);
    const [loading, setLoading] = React.useState(false);
    const [disabled, setDisabled] = React.useState(true);
    const [validationResult, setValidationResult] = React.useState<ValidationResult<Client>>({valid: false, errors: {id: '', name: '', brandName: '', first: '', last: '', address: '', phone: '', users: '', compositions: '', isActive: ''}});

    React.useEffect(() => {
        if (id) {
            setLoading(true);
            (async () => {
                const result = await clientService.get(id, dispatch);
                if (result) {
                    setClient(result as Client);
                } else {
                    toast.error('Could not find client ' + id);
                }
                
            })();
            setLoading(false);
        }
    }, [dispatch, id]);

    const onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // enable on init change
        if (disabled) {
            setDisabled(false);
        }

        const { name, value, checked } = e.target as HTMLInputElement;
        const error = validateCompositionField({name, value});
        setValidationResult((prev: ValidationResult<Client>) => ({...prev, errors: {...prev.errors, [name]: error}}));
        setClient(prev => ({
            ...prev, 
            ...(e.target.type === 'checkbox' 
                ? {[name]: checked} 
                : {[name]: value})}));
    }, [disabled]);

    const onRemove = React.useCallback(async (id: string) => {
        const result = await clientService.remove(id, dispatch);
        if (result) {
            toast.success(`Order ${result.id} removed`);
        }
    }, [dispatch]);

    const onSubmit = React.useCallback(async (client: Client) => {
        if (id) {
            const result = await clientService.update(client, dispatch);
            if (result) {
                toast.success(`Client ${result.id} updated`);
            }
        } else {
            const result = await clientService.add(client, dispatch);
            if (result) {
                toast.success(`Client ${result.id} added`);
            }
            
        }        
    }, [id, dispatch]);

    const onValidate = React.useCallback((client?: Client): boolean => {
        const {valid, errors} =  validateClient(client);
        setValidationResult({valid, errors});
        return valid;
    }, []);
    
    const onAddUser = React.useCallback((user: User) => {
        setClient(prev => ({
            ...prev,
            // users: [...prev.users, {id: '', clientId: client.id, email: '', phone: '', first: '', last: '', token: '', role: Role.Viewer, isActive: false}]
            users: [...prev.users, user]
        }));
    }, []);

    const onAddComposition = React.useCallback((composition: Composition) => {
        setClient(prev => ({
            ...prev,
            // compositions: [...prev.compositions, {id: '', clientId: client.id, title: '', description: '', materials: '', images: [], width: 0, height: 0, year: 0, price: 0}]
            compositions: [...prev.compositions, composition]
        }));
    }, []);

    return [client, loading, disabled, validationResult, onChange, onAddUser, onAddComposition, onRemove, onValidate, onSubmit];
}

export const useInvoice = (id?: string): [
    Invoice,
    boolean,
    boolean,
    InvoiceValidationResult,
    () => void, // onAddLineItem
    (sort: number) => void, // onRemoveLineItem
    (e: React.ChangeEvent<HTMLInputElement>) => void, // onChange
    (e: React.ChangeEvent<HTMLSelectElement>) => void, // onStatusChange
    (e: React.ChangeEvent<HTMLInputElement>) => void, // onDueDateChange
    (key: keyof Invoice, e: React.ChangeEvent<HTMLInputElement>) => void, // onBizEntityChange
    (sort: number, e: React.ChangeEvent<HTMLInputElement>) => void, // onLineItemChange
    (e: React.ChangeEvent<HTMLTextAreaElement>) => void, // onNotesChange
    (invoice?: Invoice) => boolean, // onValidate
    (invoice: Invoice) => Promise<void>, // onSubmit
    (id: string) => Promise<void>, // onSend
    () => void // onFake
] => {
    const dispatch = useAppDispatch();
    const [invoice, setInvoice] = React.useState<Invoice>(initInvoice);
    const [loading, setLoading] = React.useState(false);
    const [disabled, setDisabled] = React.useState(true);
    const [validationResult, setValidationResult] = React.useState<InvoiceValidationResult>({
        valid: false,
        errors: {
            id: '', 
            number: '', 
            date: '', 
            to: initBusinessEntityValidationResult, 
            from:  initBusinessEntityValidationResult, 
            lineItems: [], 
            subtotal: '', 
            tax: '', 
            discount: '', 
            total: '',
            status: '',
            balanceDue: '',
            dueDate: '',
            notes: '',
            stripePaymentLink: '',
        }
    });

    React.useEffect(() => {
        if (id) {
            setLoading(true);
            (async () => {
                const result = await invoiceService.get(id, dispatch);
                if (result) {
                    setInvoice(result as Invoice);
                } else {
                    toast.error('Could not find invoice ' + id);
                }
                
            })();
            setLoading(false);
        }
    }, [dispatch, id]);

    const onAddLineItem = React.useCallback(() => {
        setInvoice(prev => ({
            ...prev, 
            lineItems: [...prev.lineItems, {id: '', stripePriceId: '', metadata: {}, name: '', description: '', quantity: 1, price: 0, sort: prev.lineItems.length + 1}]
        }));
    }, []);

    const onRemoveLineItem = React.useCallback((sort: number) => {
        setInvoice(prev => ({
            ...prev,
            ...calculate(prev.lineItems.filter(i => i.sort !== sort)),
            lineItems: [...prev.lineItems.filter(i => i.sort !== sort)]
        }));
    }, []);

    const onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const { name, value } = e.target;
        const error = validateInvoiceField({name, value});
        setValidationResult((prev: InvoiceValidationResult) => ({...prev, errors: {...prev.errors, [name]: error}}));

        // enable on init change
        if (!error && disabled) {
            setDisabled(false);
        }

        setInvoice(prev => ({...prev, ...{[name]: value}}));
    }, [disabled]);

    const onStatusChange = React.useCallback(async (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        setLoading(true);

        const { name, value } = e.target;
        const result = await invoiceService.update({[name]: value, id}, dispatch);
        if (result) {
            toast.success(`Invoice ${result.number} updated`);
            setInvoice(prev => ({...prev, ...{[name]: value}}));
        } else {
            toast.error(`Could not update invoice ${id}`);
        }
                
        setLoading(false);
    }, [id, dispatch]);

    const onDueDateChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const { name, value } = e.target;
        // YYYY-mm-dd without the time specified is assumed in UTC and offsets the date by a day
        // https://stackoverflow.com/questions/9509360/datepicker-date-off-by-one-day
        const newDate = new Date(value + 'T00:00:00');
        const error = validateInvoiceField({key: name, value: newDate});
        setValidationResult((prev: InvoiceValidationResult) => ({...prev, errors: {...prev.errors, [name]: error}}));

        // enable on init change
        if (!error && disabled) {
            setDisabled(false);
        }

        setInvoice(prev => ({...prev, ...{[name]: newDate}}));
    }, [disabled]);

    const onBusinessEntityChange = React.useCallback((key: keyof Invoice, e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const { name, value } = e.target;
        const error = validateBusinessEntityField({[name]: value});
        setValidationResult((prev: InvoiceValidationResult) => ({           // invoice validation result
            ...prev, 
            errors: {
                ...prev.errors,                                     
                [key]: {                                                    // business entity validation result
                    ...(prev.errors as any)[key],   
                    errors: {
                        ...(prev.errors as any)[key].errors, 
                        [name]: error
                    }}}}));

        // enable on init change
        if (!error && disabled) {
            setDisabled(false);
        }

        setInvoice(prev => ({...prev, [key]: {...(prev as any)[key], [name]: value}}));
    }, [disabled]);

    const onLineItemChange = React.useCallback((sort: number, e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const { name, value } = e.target;
        const error = validateLineItemField({key: name, value});
        setValidationResult((prev: InvoiceValidationResult) => ({
            ...prev, 
            errors: {
                ...prev.errors,
                lineItems: prev.errors.lineItems?.map(i => i?.sort === sort ? {...i, errors: {...i.errors, [name]: error}} : i)
            }}));

        // enable on init change
        if (!error && disabled) {
            setDisabled(false);
        }

        setInvoice(prev => ({
            ...prev,
            ...calculate(prev.lineItems?.map(i => i?.sort === sort ? {...i, [name]: value} : i)),
            lineItems: prev ? prev.lineItems?.map(i => i?.sort === sort ? {...i, [name]: value} : i) : [{[name]: value, sort, id: '', stripePriceId: '', name: '', description: '', metadata: {}, quantity: 1, price: 0}]
        }));
    }, [disabled]);

    const onNotesChange = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault();

        const { name, value } = e.target;
        const error = validateInvoiceField({name, value});
        setValidationResult((prev: InvoiceValidationResult) => ({...prev, errors: {...prev.errors, [name]: error}}));

        // enable on init change
        if (!error && disabled) {
            setDisabled(false);
        }

        setInvoice(prev => ({...prev, ...{[name]: value}}));
    }, [disabled]);

    const onSubmit = React.useCallback(async (invoice: Invoice) => {
        setLoading(true);
        if (id) {
            const result = await invoiceService.update(invoice, dispatch);
            if (result) {
                toast.success(`Invoice ${result.number} updated`);
            }
        } else {
            const result = await invoiceService.add(invoice, dispatch);
            if (result) {
                toast.success(`Invoice ${result.number} added`);
            }

            setInvoice(initInvoice);
        }

        setValidationResult({
            valid: false,
            errors: {
                id: '', 
                number: '', 
                date: '', 
                to: initBusinessEntityValidationResult, 
                from:  initBusinessEntityValidationResult, 
                lineItems: [], 
                subtotal: '', 
                tax: '', 
                discount: '', 
                total: '',
                status: '',
                balanceDue: '',
                dueDate: '',
                notes: '',
                stripePaymentLink: '',
            }
        });
        setDisabled(true);
        setLoading(false);
    }, [id, dispatch]);

    const onSend = React.useCallback(async (id: string) => {
        setLoading(true);
        
        const result = await invoiceService.send(id);
        if (result) {
            toast.success(`Invoice ${invoice.number} sent`);
        }
        setLoading(false);
    }, [invoice.number]);

    const onValidate = React.useCallback((invoice?: Invoice): boolean => {
        const {valid, errors} = validateInvoice(invoice);
        setValidationResult({valid, errors});
        if (!valid) {
            setDisabled(true);
        }

        return valid;
    }, []);

    const onFake = React.useCallback(() => {
        const invoice = randomTestInvoice();
        const {valid, errors} = validateInvoice(invoice);
        setValidationResult({valid, errors});
        if (valid) {
            setDisabled(false);
            setInvoice(invoice);
        }
        
    }, []);

    return [invoice, loading, disabled, validationResult, onAddLineItem, onRemoveLineItem, onChange, onStatusChange, onDueDateChange, onBusinessEntityChange, onLineItemChange, onNotesChange, onValidate, onSubmit, onSend, onFake];
}

export const useResourceFiles = (bucketName: string, resourceId: string | undefined, category: string, onFilesChange: (bucketName: string, resourceId: string, category: string) => Promise<void>, onLoadingChange: (loading: boolean) => void): [
    (bucketName: string, f: GridFsFile) => Promise<void>,                                                                       // onFilePreview
    (bucketName: string, resourceId: string, category: string,  fileId: string) => Promise<void>                                // onFileDelete                                                   
] => {
    const dispatch = useAppDispatch();   

    React.useEffect(() => {
        if (resourceId) {
            const getCompositionFiles = async () => { 
                await onFilesChange(bucketName, resourceId, category);            
            }
            getCompositionFiles();
        }
        
    }, [bucketName, resourceId, category, onFilesChange, dispatch]);

    const onFilePreview = React.useCallback(async (bucketName: string, f: any) => {
        onLoadingChange(true);
        if (!f._id) {
           console.log('No file id');
           return;
        }

        const stream = await fileService.download(bucketName, f._id, dispatch);
        //Create a Blob from the PDF Stream
        const file = new Blob(
            [stream], 
            {type: f.contentType});
        //Build a URL from the file
        const fileUrl = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileUrl);
        onLoadingChange(false);
    }, [dispatch]);

    const onFileDelete = React.useCallback(async (bucketName: string, resourceId: string, category: string, fileId: string) => {
        onLoadingChange(true);
        const result = await fileService.remove(bucketName, fileId, dispatch);

        await onFilesChange(bucketName, resourceId, category);

        toast.success(result);
        onLoadingChange(false);
    }, [dispatch, onFilesChange]);    

    return [onFilePreview, onFileDelete];
}



export const useFileToImage = (): [(bucketName: string, files: GridFsFile[]) => Promise<Image[]>] => {
    const dispatch = useAppDispatch();

    const fileToImage = async (bucketName: string, files: GridFsFile[]): Promise<Image[]> => {
        const promises = files
            .sort((a,b) => Number(new Date(a.uploadDate || 0)) - Number(new Date(b.uploadDate || 0))) // chronological order
            .map(async (f: GridFsFile, i: number) => {
                const stream = await fileService.download(bucketName, f._id, dispatch);
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [stream], 
                    {type: f.contentType});
                //Build a URL from the file
                const fileUrl = URL.createObjectURL(file);
        
                return {src: fileUrl, alt: f.filename, sort: i + 1} as Image;
            });
        const images = await Promise.all(promises);
    
        return images;
    }
    
    return [fileToImage];
}

export const useModal = (): [React.MutableRefObject<HTMLDivElement | null>, () => void, () => void] => {
    const modalRef = React.useRef<HTMLDivElement | null>(null);

    const onShow = () => {
        const modalEle = modalRef.current;
        const bsModal = new bootstrap.Modal(modalEle!, {
            backdrop: true,
            keyboard: false
        });
        bsModal.show();
    }
    
    const onHide = () => {
        const modalEle = modalRef.current;
        const bsModal= bootstrap.Modal.getInstance(modalEle!);
        bsModal?.hide();
    }

    return [modalRef, onShow, onHide];
}

export const useCollapse = (): [React.MutableRefObject<HTMLDivElement | null>, () => void, () => void, () => void] => {
    const ref = React.useRef<HTMLDivElement | null>(null);

    const onToggle = () => {
        const element = ref.current;
        const bsCollapse = new bootstrap.Collapse(element!);
        bsCollapse?.toggle();
    }

    const onShow = () => {
        const element = ref.current;
        const bsCollapse= bootstrap.Collapse.getInstance(element!);
        bsCollapse?.show();
    }
    
    const onHide = () => {
        const element = ref.current;
        const bsCollapse= bootstrap.Collapse.getInstance(element!);
        bsCollapse?.hide();
    }

    return [ref, onToggle, onShow, onHide];
}

export const useDropdown = (): [React.MutableRefObject<HTMLDivElement | null>, () => void, () => void, () => void] => {
    const ref = React.useRef<HTMLDivElement | null>(null);

    const onToggle = () => {
        const element = ref.current;
        const bsCollapse = new bootstrap.Dropdown(element!);
        bsCollapse?.toggle();
    }

    const onShow = () => {
        const element = ref.current;
        const bsCollapse= bootstrap.Dropdown.getInstance(element!);
        bsCollapse?.show();
    }
    
    const onHide = () => {
        const element = ref.current;
        const bsCollapse= bootstrap.Dropdown.getInstance(element!);
        bsCollapse?.hide();
    }

    return [ref, onToggle, onShow, onHide];
}

export const useDropdownButton = (): [React.MutableRefObject<HTMLButtonElement | null>, () => void, () => void, () => void] => {
    const ref = React.useRef<HTMLButtonElement | null>(null);

    const onToggle = () => {
        const element = ref.current;
        const bsCollapse = new bootstrap.Dropdown(element!);
        bsCollapse?.toggle();
    }

    const onShow = () => {
        const element = ref.current;
        const bsCollapse= bootstrap.Dropdown.getInstance(element!);
        bsCollapse?.show();
    }
    
    const onHide = () => {
        const element = ref.current;
        const bsCollapse= bootstrap.Dropdown.getInstance(element!);
        bsCollapse?.hide();
    }

    return [ref, onToggle, onShow, onHide];
}
import { ArtistForm } from "../../components/ArtistForm";
import { Spinner } from "../../components/Spinner";
import { ArtistContext } from "../../contexts/ArtistContext";
import { useArtistContext } from "../../hooks/artist/useArtistContext";

export const ArtistCreate = () => {
    const context = useArtistContext();

    return(context.loading ? <Spinner /> :
        <ArtistContext.Provider value={context}>
            <ArtistForm />
        </ArtistContext.Provider>);
}
import { capitalize } from '../utils/formatUtils';
import { Artist } from './Artist';
import {Image } from './Image';
import { CompositionValidationResult, ValidationResult } from './ValidationResult';

export interface BucketFile {
    id?: string;
    name: string;
    url: string;
    type?: string;
    lastModified?: Date;
    size?: number;
}

export interface Location {
    id?: string;
    name: string;
    detail: string;
    date: Date | null;
    notes: string;
    isCurrent: boolean;
    sort: number;
}

export interface Composition {
    id?: string;
    clientId: string;
    stockNumber: string;
    artist: Artist;
    title: string;
    medium: string;
    images: Image[];
    financialFiles: BucketFile[];
    price: number;
    width: number;
    height: number;
    year: number;
    isUnique: boolean;
    isActive: boolean;
    provenance: string;
    exhibitions: string;
    soldDate: Date | null,
    locations: Location[];
}

export function initComposition(clientId: string): Composition {
    const initImages: Image[] = [];
    const initFinancialFiles: BucketFile[] = [];
    const initArtist: Artist = {first: '', last: '', name: '', address1: '', address2: '', city: '', state: '', zip: '', phone: '', email: '', website: '', compositions: [], isActive: false};
    const initLocations = [{name: '', detail: '', date: null, notes: '', isCurrent: false, sort: 1}];
    const initComposition = {clientId, stockNumber: '', artist: initArtist, title: '', medium: '', images: initImages, financialFiles: initFinancialFiles, price: 0, width: 0, height: 0, year: 0, isUnique: true, isActive: false, provenance: '', exhibitions: '', soldDate: null, locations: initLocations};

    return initComposition;
}

export function validateComposition(composition?: Partial<Composition>): CompositionValidationResult {
    const validationResult: CompositionValidationResult = {
        valid: false,
        errors: {clientId: '', stockNumber: '', artist: '', title: '', medium: '', images: '', financialFiles: '', price: '', width: '', height: '', year: '', isUnique: '', isActive: '', provenance: '', exhibitions: '', soldDate: '', locations: []}
    };

    if (!composition || Object.entries(composition).length === 0) {
        for (const key in validationResult.errors) {
            (validationResult.errors as any)[key] = validateCompositionField({key, value: undefined});
        }    
        return validationResult;
    }

    let key: keyof Composition;
    for (key in composition) {
        const value = composition ? composition[key] : undefined;
        (validationResult.errors as any)[key] = validateCompositionField({key, value});
    }    

    // no errors, validate
    if (Object.entries(validationResult.errors).every(e => !e[1])) {
        validationResult.valid = true;
    }

    return validationResult; 
}

export function validateCompositionField({key, value}: {[key: string]: string | number | boolean | Date | Artist | Image[] | BucketFile[] | Location[] | null | undefined}): string | ValidationResult<Location>[] | null {
    let error: string | ValidationResult<Location>[] | null = null;

    switch (key) {
        case 'artist':
            if (!(value as Artist).id) {
                error = 'Artist required';
            }
            break;
        case 'title':
            if (!value) {
                error = 'Title is required';
            }
            break;
        case 'medium':
            if (!value) {
                error = 'Medium is required';
            }
            break;
        case 'price':
            if (!value) {
                error = 'Price is required';
            } else if (Number(value) === 0) {
                error = 'Price cannot be zero';
            }
            break;
        case 'width':
            if (!value) {
                error = 'Width is required';
            } else if (Number(value) === 0) {
                error = 'Width cannot be zero';
            }
            break;
        case 'height':
            if (!value) {
                error = 'Height is required';
            } else if (Number(value) === 0) {
                error = 'Height cannot be zero';
            }
            break;
        case 'year':
            if (!value) {
                error = 'Year is required';
            } else if (Number(value) === 0) {
                error = 'Year cannot be zero';
            }
            break;
        case 'locations':
                if (!value || (Array.isArray(value) && value.length === 0)) {
                    error = [{errors: {name: 'At least one location is required'}} as ValidationResult<Location>];
                } else {
                    const lvrs = (value as Location[]).map(li => {
                        const locationValidationResult = validateLocation(li);
                        return {...locationValidationResult, sort: li.sort };
                    });
    
                    if (lvrs.some(vr => !vr.valid)) {
                        error = lvrs;
                    }
                }
                break;
        default:
            break;
    }
    if(error) console.log('Composition validation error', error);
    return error;
}

export function validateLocation(location?: Partial<Location>) {
    const validationResult: ValidationResult<Location> = {
        valid: false,
        errors: {
            id: '',
            name: '',
            detail: '',
            date: '',
            notes: '',
            isCurrent: '',
            sort: ''
        }
    };

    if (!location || Object.entries(location).length === 0) {
        for (const key in validationResult.errors) {
            // itemize errors by key to know exactly what is missing
            (validationResult.errors as any)[key] = validateLocationField({key, value: undefined});
        }    
        return validationResult;
    }

    let key: keyof Location;
    for (key in location) {
        const value = location ? location[key] : undefined;
        validationResult.errors[key] = validateLocationField({key, value});
    }    

    // no errors, validate
    if (Object.entries(validationResult.errors).every(e => !e[1])) {
        validationResult.valid = true;
    }

    return validationResult; 
}

export function validateLocationField({key, value}: {[key: string]: string | number | Date | boolean | null | undefined}): string {
    let error = '';
    
    switch (key) {
        case 'name':
        case 'detail':        
            if (!value) {
                error = `${capitalize(key as string)} is required`;
            }
            break;
        case 'date':
            if (!value) {
                error = `${capitalize(key as string)} is required`;
            } else if(value === 'Invalid Date') {
                error = `${capitalize(key as string)} is invalid`;
            }
            break;
        default:
            break;
    }
    if(error) console.log('Composition location validation error', error);
    return error;
}
import React from "react";
import { Image } from "../types/Image";
import { CompositionContext, CompositionContextType } from "../contexts/CompositionContext";
import { useDrag, useDrop } from "react-dnd";

// raw react drag and drop
export const ImageListSortableOld = ({images}: {images: Image[]}) => {
    const {dragImageRef, dragOverImageRef, onChange, onSortImagesOld, onRemoveImage} = React.useContext<CompositionContextType>(CompositionContext);
    const compositionImage = React.useCallback((id: string) => images?.find(x => x.id === id), [images]);

    return(<>
        {images.map((image, i) => 
            <div key={`image-${image.id}`} className="d-flex mb-2">
                <div className="w-75 me-3">
                    <div className="input-group input-group-sm">
                        <span className="input-group-text">
                            <div key={`sort-${image.id}`} style={{cursor: 'move'}}
                                draggable
                                onDragStart={() => dragImageRef.current = i}
                                onDragEnter={() => dragOverImageRef.current = i}
                                onDragEnd={onSortImagesOld}
                                onDragOver={(e: React.DragEvent<HTMLDivElement>) => e.preventDefault()}>
                                <i className="bi bi-grip-vertical" />{image.sort}
                            </div>
                        </span>
                        <input type="text" className="form-control form-control-sm" data-sort={image.sort} name="src" value={compositionImage(image.id!)?.src} onChange={onChange} />
                    </div>
                </div>
                <div className="w-25">
                    <input type="text" className="form-control form-control-sm" data-sort={image.sort} name="alt" value={compositionImage(image.id!)?.alt} onChange={onChange} />
                </div>
                {image.sort !== 1 && <button className="btn btn-outline-danger btn-sm border-0 ms-1" onClick={() => onRemoveImage(image.sort)}><i className="bi-x" /></button>}
            </div>)}
    </>);
}

enum DragItemType {
    Image = 'image'
}

// react dnd https://react-dnd.github.io/react-dnd/examples/sortable/cancel-on-drop-outside
export const ImageListSortable = ({images}: {images: Image[]}) => {
    const [, drop] = useDrop(() => ({ accept: DragItemType.Image }));
    const {onChange, onSortImages, onRemoveImage} = React.useContext<CompositionContextType>(CompositionContext);
    const findImage = React.useCallback((id: string) => ({
        image: images?.find(x => x.id === id), 
        index: images?.findIndex(x => x.id === id)
    }), [images]);

    return(<div ref={drop}>
        {images.map((image, ix) => (<ImageSortable key={`image-${image.id}`} image={image} index={ix} onFindImage={findImage} onSortImages={onSortImages} onChange={onChange} onRemoveImage={onRemoveImage} />))}
    </div>);
}

interface ImageSortableProps {
    image: Image, 
    index: number, 
    onFindImage: (id: string) => { image: Image | undefined, index: number | undefined },
    onSortImages: (id: string, atIndex: number) => void,
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    onRemoveImage: (sort: number) => void
}

const ImageSortable = React.memo(({image, index: originalIndex, onFindImage, onSortImages, onChange, onRemoveImage}: ImageSortableProps) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: DragItemType.Image,
        item: { id: image.id, originalIndex },
        collect: (monitor) => ({ isDragging: monitor.isDragging() }),
        end: (item, monitor) => {
            const { id: droppedId, originalIndex } = item
            const didDrop = monitor.didDrop()
            if (!didDrop) {
                onSortImages(droppedId!, originalIndex)
            }
        },
    }), [image.id, originalIndex, onSortImages]);

    const [, drop] = useDrop(() => ({
        accept: DragItemType.Image,
        hover({ id: draggedId }: {id: string}) {
            if (draggedId !== image.id) {
                const { index: overIndex } = onFindImage(image.id!);
                onSortImages(draggedId, overIndex!);
            }
        },
    }), [onFindImage, onSortImages]);

    const opacity = isDragging ? 0 : 1;

    return(<div className="d-flex mb-2" style={{opacity}}>
            <div className="w-75 me-3">
                <div className="input-group input-group-sm">
                    <span className="input-group-text">
                        <div ref={(node) => drag(drop(node))} key={`sort-${image.id}`} style={{cursor: 'move'}}>
                            <i className="bi bi-grip-vertical" />{image.sort}
                        </div>
                    </span>
                    <input type="text" className="form-control form-control-sm" data-sort={image.sort} name="src" value={image?.src} onChange={onChange} />
                </div>
            </div>
            <div className="w-25">
                <input type="text" className="form-control form-control-sm" data-sort={image.sort} name="alt" value={image?.alt} onChange={onChange} />
            </div>
            {image.sort !== 1 && <button className="btn btn-outline-danger btn-sm border-0 ms-1" onClick={() => onRemoveImage(image.sort)}><i className="bi-x" /></button>}
        </div>);
});
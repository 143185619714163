import { capitalize } from "../utils/formatUtils";
import { BusinessEntity } from "./BusinessEntity";
import { Composition } from "./Composition";
import { ValidationResult } from "./ValidationResult";

export interface Artist extends BusinessEntity {
    compositions: Composition[];
}

export function validateArtist(artist?: Partial<Artist>) {
    const validationResult: ValidationResult<Artist> = {
        valid: false,
        errors: {
            id: '',
            first: '',
            last: '',
            name: '', // could be business name
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            email: '',
            website: '',
            compositions: '',
            isActive: ''
        }
    };

    if (!artist || Object.entries(artist).length === 0) {
        for (const key in validationResult.errors) {
            // itemize errors by key to know exactly what is missing
            (validationResult.errors as any)[key] = validateArtistField({key, value: undefined});
        }    
        return validationResult;
    }

    let key: keyof Artist;
    for (key in artist) {
        const value = artist ? artist[key] : undefined;
        validationResult.errors[key] = validateArtistField({key, value});
    }    

    // no errors, validate
    if (Object.entries(validationResult.errors).every(e => !e[1])) {
        validationResult.valid = true;
    }

    return validationResult; 
}

export function validateArtistField({key, value}: {[key: string]: string | boolean | Composition[] | null | undefined}): string {
    let error = '';
    
    switch (key) {
        case 'first':
        case 'last':        
            if (!value) {
                error = `${capitalize(key as string)} name is required`;
            }
            break;
        default:
            break;
    }
    if(error) console.log('Artist validation error', error);
    return error;
}
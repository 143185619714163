import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Composition } from "../../types/Composition";
import { compositionService } from "./compositionService";
import { toast } from "react-toastify";
import { narrowError } from "../../utils/errorUtils";
import { BaseState } from "../shared/BaseState";
import { ListQuery } from "../shared/Query";

interface CompositionState extends BaseState {
    compositions: Composition[];
    count: number;
}

const initialState: CompositionState = {
    loading: false,
    success: false,
    error: undefined,
    compositions: [],
    count: 0
}

// Get compositions
export const getAll = createAsyncThunk(
    "compositions/getAll",
    async (query: ListQuery, thunkAPI) => {
        try {
            const result = await compositionService.getAll(query, thunkAPI.dispatch);
            return result;
        } catch (error) {
            const message = narrowError(error);
            toast.error(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Create composition
export const add = createAsyncThunk(
    "compositions/add",
    async (composition: Composition, thunkAPI) => {
        try {
            const created = await compositionService.add(composition, thunkAPI.dispatch);
            if (created?.id) {
                toast.success(`Composition ${created.id} added`);
            } else {
                toast.error('Failed to created composition');
            }
            return created;
        } catch (error) {
            const message = narrowError(error);
            toast.error(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Update composition
export const update = createAsyncThunk(
    "compositions/update",
    async (composition: Composition, thunkAPI) => {
        try {
            const updated = await compositionService.update(composition, thunkAPI.dispatch);
            if (updated?.id) {
                toast.success(`Composition ${updated.id} updated`);
            } else {
                toast.error('Failed to update composition');
            }
            return updated;
        } catch (error) {
            const message = narrowError(error);
            toast.error(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Remove composition
export const remove = createAsyncThunk(
    "compositions/remove",
    async (id: string, thunkAPI) => {
        try {
            const result = await compositionService.remove(id, thunkAPI.dispatch);
            if (result.removed?.id) {
                toast.success(`Composition ${result.removed.id} removed`);
            } else {
                toast.error(result.message);
            }
            return result;
        } catch (error) {
            const message = narrowError(error);
            toast.error(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const compositionSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAll.pending, (state) => { state.loading = true; })
            .addCase(getAll.fulfilled, (state, action: PayloadAction<any>) => {
                const {compositions, count} = action.payload;
                return {...state, loading: false, success: true, compositions, count};
            })
            .addCase(getAll.rejected, (state, action) => {
                return {...state, loading: false, success: false, error: narrowError(action.payload)};
            })
            .addCase(add.pending, (state) => { state.loading = true; })
            .addCase(add.fulfilled, (state, action: PayloadAction<Composition | undefined>) => {
                return {...state, loading: false, success: !!action.payload?.id};
            })
            .addCase(add.rejected, (state, action) => {
                return {...state, loading: false, success: false, error: narrowError(action.payload)};
            })
            .addCase(update.pending, (state) => { state.loading = true; })
            .addCase(update.fulfilled, (state, action: PayloadAction<Composition | undefined>) => {
                return {...state, loading: false, success: !!action.payload?.id, compositions: [...state.compositions.map(c => c.id === action.payload?.id ? action.payload as Composition : c)]};
            })
            .addCase(update.rejected, (state, action) => {
                return {...state, loading: false, success: false, error: narrowError(action.payload)};
            })
            .addCase(remove.pending, (state) => { state.loading = true; })
            .addCase(remove.fulfilled, (state, action: PayloadAction<any>) =>{
                return {...state, loading: false, success: !!action.payload?.id, compositions: state.compositions.filter(c => c.id !== action.payload?.removed.id), count: action.payload?.count};
            })
            .addCase(remove.rejected, (state, action) => {
                return {...state, loading: false, success: false, error: narrowError(action.payload)};
            })
    }
});

export default compositionSlice.reducer;
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate, Outlet, Link } from "react-router-dom";
import { Login } from "./pages/Login";
import { useAppDispatch, useAppSelector } from "./hooks";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { Composition } from "./pages/Composition";
import { Compositions } from "./pages/Compositions";
import { Role } from "./types/User";
import { Cart } from "./components/Cart";
import { Checkout } from "./pages/Checkout";
import { Confirmation } from "./pages/Confirmation";
import { TopNav } from "./components/TopNav";
import { Dashboard } from "./pages/admin/Dashboard";
import { CompositionsList } from "./pages/admin/CompositionsList";
import { CompositionDetails } from "./pages/admin/CompositionDetails";
import { CompositionCreate } from "./pages/admin/CompositionCreate";
import { Orders } from "./pages/admin/Orders";
import { OrderDetails } from "./pages/admin/Order";
import { Clients } from "./pages/admin/Clients";
import { Client } from "./pages/admin/Client";
import { ClientCreate } from "./pages/admin/ClientCreate";
import { User } from "./pages/admin/User";
import { UserCreate } from "./pages/admin/UserCreate";
import { Users } from "./pages/admin/Users";
import { Home } from "./pages/Home";
import { guestService } from "./features/guest/guestService";
import { Guest } from "./types/Guest";
import { Guests } from "./pages/admin/Guests";
import { Contact } from "./pages/Contact";
import { About } from "./pages/About";
import { Invoices } from "./pages/admin/Invoices";
import { Invoice } from "./pages/admin/Invoice";
import { InvoiceCreate } from "./pages/admin/InvoiceCreate";
import { logout } from "./features/auth/authSlice";
import { Artists } from "./pages/admin/Artists";
import { Artist } from "./pages/admin/Artist";
import { ArtistCreate } from "./pages/admin/ArtistCreate";

function RequireAuth({ children }: { children: JSX.Element }) {
    const { user } = useAppSelector((state) => state.auth);
    const location = useLocation();
  
    if (!user) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return children;
}
  
function Layout() {
    const {pathname} = useLocation();
    return (
      <div className="h-100 main-background">
        {/* A "layout route" is a good place to put markup you want to
            share across all the pages on your site, like navigation. */}
        <TopNav />
        {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
        {pathname === '/' 
            ? <>
                <Outlet />
                <EmailSubscription />
                <Footer />
            </>
            : <div className="d-flex container align-items-center" style={{minHeight: 'calc(100% - 100px)'}}> {/* 100px - navbar offset*/}
                <Outlet />
            </div>}
        <Cart />
        <ToastContainer position="top-center" theme="light" autoClose={5000} hideProgressBar={true} newestOnTop={true} />
      </div>
    );
}

function Footer() {
    return(<footer className="d-flex justify-content-center align-items-center py-4">
        <div className="d-flex justify-content-center">
            <Link to='https://facebook.com/alyssabreid.art' className="link-dark mx-3" target="_blank">
                <i className="bi bi-facebook" style={{fontSize: '2rem'}}  />
            </Link>
            <Link to='https://instagram.com/alyssabreid.art' className="link-dark mx-3" target="_blank">
                <i className="bi bi-instagram" style={{fontSize: '2rem'}}  />
            </Link>
            <Link to='https://pintrest.com' className="mx-3 d-flex align-items-center" target="_blank">
                <img src="https://www.svgrepo.com/show/35416/pinterest-round-logo.svg" alt="Pintrest" width="32" height="32" />
            </Link>
        </div>        
    </footer>);
}

function EmailSubscription() {
    const [email, setEmail] = React.useState('');
    const [error, setError] = React.useState('');

    const handleSubscribe = async () => {
        // TODO: improve validation
        const valid = /\S+@\S+\.\S+/.test(email) && email.length < 255;
        
        if (valid) {
            setError('');
            const result = await guestService.add({email, subscribed: true} as Guest);
            if (result) {
                setEmail('');
                toast.success(`${email} was added successfully`);
            }
        } else {
            setError('Email is invalid');
        }
    }

    return(
        <div className="d-flex flex-column align-items-center mx-3">
            <span className="my-2">SUBSCRIBE TO OUR EMAILS</span>
            <span className="text-muted text-center my-2">Subscribe to our mailing list for first access to art drops, gallery updates, and more.</span>
            <div className="input-group has-validation justify-content-center">
                <input 
                    type="email" 
                    className={`form-control stripe-style-input ${error ? "is-invalid" : ""}`} 
                    placeholder="Email" 
                    style={{maxWidth: '300px'}} 
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} 
                    value={email} />
                <button className="btn btn-dark" type="button" onClick={handleSubscribe}><i className="bi bi-arrow-right" /></button>
            </div>
            {error && <span className="invalid-feedback d-block w-auto">{error}</span>}
        </div>);
}

function AdminLayout() {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.auth);
    const {pathname} = useLocation();

    const handleLogout = React.useCallback(() => {
        dispatch(logout());
    }, [dispatch]);
    
    return(
        <div className="container-fluid">
            <div className="row flex-nowrap">
                <div className="col-auto col-xl-2 px-sm-2 px-0 bg-dark">
                    <div className="d-flex flex-column align-items-center align-items-sm-start px-2 pt-2 text-white min-vh-100 sticky-top">
                        <Link className={`btn btn-dark w-100 rounded-0 text-start px-0 px-lg-2 ${pathname === '/admin' ? 'active' : ''}`} to="">
                            <i className="bi bi-speedometer" />
                            <span className="ms-2 d-none d-lg-inline">Dashboard</span>
                        </Link>
                        <Link className={`btn btn-dark w-100 rounded-0 text-start px-0 px-lg-2 ${pathname === '/admin/artists' ? 'active' : ''}`} to="artists">
                            <i className="bi bi-person-workspace" />
                            <span className="ms-2 d-none d-lg-inline">Artists</span>
                        </Link>
                        <Link className={`btn btn-dark w-100 rounded-0 text-start px-0 px-lg-2 ${pathname === '/admin/compositions' ? 'active' : ''}`} to="compositions">
                            <i className="bi bi-easel" />
                            <span className="ms-2 d-none d-lg-inline">Compositions</span>
                        </Link>
                        <div className="d-flex flex-column mt-auto mb-3 w-100">
                            <button type="button" disabled className="btn btn-dark w-100 rounded-0 text-start px-0 px-lg-2">
                                <i className="bi bi-person-badge" />
                                <span className="ms-2 d-none d-lg-inline">{user?.first} {user?.last}</span>
                            </button>
                            <button type="button" className="btn btn-dark rounded-0 w-100 text-start px-0 px-lg-2" onClick={handleLogout}>
                                <i className="bi bi-box-arrow-right" />
                                <span className="ms-2 d-none d-lg-inline">Log Out</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col py-3 main-background">
                    <Outlet />
                </div>
            </div>
            <ToastContainer position="top-center" theme="light" autoClose={5000} hideProgressBar={true} newestOnTop={true} />
        </div>
    );
}

function App() {
    return(
        <Router>
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path="/" element={<RequireAuth><AdminLayout /></RequireAuth>}>
                    {/* <Route index element={<Home />} />
                    <Route path='about' element={<About />} />
                    <Route path='checkout' element={<Checkout />} />
                    <Route path='confirmation' element={<Confirmation />} />
                    <Route path='compositions' element={<Compositions />} />
                    <Route path="compositions/:id" element={<Composition />} />
                    <Route path="contact" element={<Contact />} /> */}
                    <Route index element={<Dashboard />} />
                    <Route path='artists' element={<Artists />} />
                    <Route path='artists/:id' element={<Artist />} />
                    <Route path='artists/create' element={<ArtistCreate />} />
                    <Route path='compositions' element={<CompositionsList />} />
                    <Route path='compositions/:id' element={<CompositionDetails />} />
                    <Route path='compositions/create' element={<CompositionCreate />} />
                    {/* <Route path='clients' element={<Clients />} />
                    <Route path='clients/:id' element={<Client />} />
                    <Route path='clients/create' element={<ClientCreate />} />
                    <Route path='orders' element={<Orders />} />
                    <Route path='orders/:id' element={<OrderDetails />} />
                    <Route path='users' element={<Users />} />
                    <Route path='users/:id' element={<User />} />
                    <Route path='users/create' element={<UserCreate />} />
                    <Route path='guests' element={<Guests />} />
                    <Route path='invoices' element={<Invoices />} />
                    <Route path='invoices/:id' element={<Invoice />} />
                    <Route path='invoices/create' element={<InvoiceCreate />} /> */}
                </Route>
                {/* Admin Routes */}
                {/* <Route path="admin" element={<RequireAuth><AdminLayout /></RequireAuth>}>
                    <Route index element={<Dashboard />} />
                    <Route path='compositions' element={<CompositionsList />} />
                    <Route path='compositions/:id' element={<CompositionDetails />} />
                    <Route path='compositions/create' element={<CompositionCreate />} />
                    <Route path='clients' element={<Clients />} />
                    <Route path='clients/:id' element={<Client />} />
                    <Route path='clients/create' element={<ClientCreate />} />
                    <Route path='orders' element={<Orders />} />
                    <Route path='orders/:id' element={<OrderDetails />} />
                    <Route path='users' element={<Users />} />
                    <Route path='users/:id' element={<User />} />
                    <Route path='users/create' element={<UserCreate />} />
                    <Route path='guests' element={<Guests />} />
                    <Route path='invoices' element={<Invoices />} />
                    <Route path='invoices/:id' element={<Invoice />} />
                    <Route path='invoices/create' element={<InvoiceCreate />} />
                </Route> */}
            </Routes>
        </Router>
    );
}

export default App;

import { toast } from "react-toastify";
import { narrowError } from "../../utils/errorUtils";
import axios from "axios";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";

const API_URL = `${process.env.REACT_APP_API_DOMAIN}/api/files`;

const upload = async (formData: FormData, setProgressPct: (value: React.SetStateAction<number>) => void, dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    try {
        const data = await axios.post(`${API_URL}/uploadS3`, formData, {
                headers: { "Accept": "multipart/form-data", "Content-Type": "multipart/form-data" },
                transformRequest: formData => formData, // https://github.com/axios/axios/issues/4885#issuecomment-1222419132
                // onUploadProgress: progressEvent => {
                //     setProgressPct(Math.round((progressEvent.loaded * 100) / progressEvent.total!));
            
                //     // Clear progress
                //     setTimeout(() => setProgressPct(0), 3000);
                // }
            })
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });

        return data;
    } catch (error) {
        console.log(error);
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not upload composition file', message);
    } 
}

const download = async (bucketName: string, fileId: string, dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    try {
        const data = await axios
            .get(`${API_URL}/download/${bucketName}/${fileId}`, {responseType: 'arraybuffer'})
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });

        return data;
    } catch (error) {
        console.log(error);
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not get download file', message);
    }
}

const getResourceFiles = async (bucketName: string, resourceId: string, dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    try {
        const data = await axios.get(`${API_URL}/${bucketName}/${resourceId}`)
        .then(response => response.data)
        .catch((error) => {
            console.error(error);
            if (error.response.status === 401 || error.response.status === 403) {
                dispatch(logout());
            }
        });
    return data;
    } catch (error) {
        console.log(error);
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not get file for resource', message);
    }
}

 const remove = async (bucketName: string, fileId: string, dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    try {
        const data = await axios
            .delete(`${API_URL}/${bucketName}/${fileId}`)
            .then(response => response.data)
            .catch((error) => {
                console.error(error);
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(logout());
                }
            });

        return data;
    } catch (error) {
        console.log(error);
        const message = narrowError(error);
        toast.error(message);
        console.error('Could not get download file', message);
    }
}

export const fileService = { download, getResourceFiles, upload, remove };
import React from "react";
import { useAppDispatch, useAppSelector, useModal } from "../../hooks";
import { Link, useNavigate } from "react-router-dom";
import { Artist } from "../../types/Artist";
import { Pagination, limits } from "../../components/Pagination";
import { getAll, remove } from "../../features/artists/artistSlice";
import { Spinner } from "../../components/Spinner";
import { InputClearable } from "../../components/InputClearable";
import { Role } from "../../types/User";
import { ArtistConfirmRemoveBody, ConfirmActionFooter, Modal } from "../../components/Modal";
import { EllipsisDropdown } from "../../components/EllipsisDropdown";

export const Artists = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { artists, loading, count } = useAppSelector(state => state.artists);
    const { user } = useAppSelector(state => state.auth);
    const [selected, setSelected] = React.useState<Artist>({} as Artist);
    const [modalRef, showModal, hideModal] = useModal();

    const [searchText, setSearchText] = React.useState<string>('');    
    const [limit, setLimit] = React.useState<number>(limits[0]);
    const [currentPage, setCurrentPage] = React.useState<number>(1);

    React.useEffect(() => {
        dispatch(getAll({search: searchText, page: currentPage, limit}));
    }, [currentPage, limit, dispatch, searchText]);

    const handleRemove = React.useCallback((id: string) => {
        setSelected(artists.find(o => o.id === id) as Artist);
        showModal();
    }, [artists, showModal]);

    const handleConfirmRemove = React.useCallback(() => {
        hideModal();
        dispatch(remove(selected.id!));
        setSelected({} as Artist);
    }, [dispatch, hideModal, selected.id]);

    const handleLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        setCurrentPage(1);
        setLimit(Number(e.target.value));
    }

    const handleSearchTextChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => { 
        e.preventDefault();
        setSearchText(e.target.value);
    }, []);

    const handleSearch = React.useCallback(async () => {
        setCurrentPage(1);
        dispatch(getAll({search: searchText, page: 1, limit}));
    }, [dispatch, limit, searchText]);

    const handleSearchTextClear = React.useCallback(async () => {
        setSearchText('');
        setCurrentPage(1);
        setLimit(10);
        dispatch(getAll({}));
    }, [dispatch]);

    return(loading ? <Spinner /> : 
        <div className="m-3">
            <div className="d-flex align-items-center my-3">
                <div className="d-flex input-group input-group-sm w-50">
                    <InputClearable placeholder="Title, Description, or Materials" value={searchText} onChange={handleSearchTextChange} onClear={handleSearchTextClear} className="w-50" inputClassName="form-control-sm input-group-clearable" buttonClassName="btn-sm" />
                    <button className="btn btn-outline-secondary" onClick={handleSearch} type="button">Search</button>
                </div>
                <button className="btn btn-primary btn-sm ms-auto" onClick={() => navigate("create")}><i className="bi-plus" /> New Artist</button>
            </div>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th></th>
                        <th>First</th>
                        <th>Last</th>
                        <th>Active</th>
                        <th className="text-center">Compositions</th>                        
                        {(user?.role === Role.Admin || user?.role === Role.Super) && <th className="text-center"><i className="bi bi-menu-down" /></th>}
                    </tr>
                </thead>
                <tbody>
                    {artists?.length > 0 && artists.map((a, i) => 
                        <tr key={a.id} className="align-middle">
                            <td>
                                <Link to={`${a.id}`}>
                                    {i + 1}
                                </Link></td>
                            <td>{a.first}</td>
                            <td>{a.last}</td>
                            <td>{a.isActive ? 'Active' : 'Inactive'}</td>
                            <td className="text-center">{a.compositions?.length}</td>
                            {(user?.role === Role.Admin || user?.role === Role.Super) && <td className="text-center">
                                <EllipsisDropdown>
                                    <ul className="dropdown-menu">
                                        <li><button className="btn btn-sm dropdown-item" onMouseDown={() => handleRemove(a.id!)} disabled={a.compositions?.length > 0}>Remove</button></li>
                                    </ul>
                                </EllipsisDropdown>
                            </td>}
                        </tr>)}
                </tbody>
            </table>
            <div className="text-center my-3">
                <span className="float-start">{count} {count === 1 ? 'Artist' : 'Artists'}</span>
                <Pagination
                    currentPage={currentPage}
                    count={count}
                    pageSize={limit}
                    setCurrentPage={setCurrentPage}
                />
                {artists?.length > 0 && <div className="d-flex align-items-center float-end">
                    <span className="mx-2 small">Show</span>
                    <select className="form-select form-select-sm" value={limit} style={{width: '70px'}} onChange={handleLimitChange}>
                        {limits.map(o => <option key={'limit-option-' + o} value={o}>{o}</option>)}
                    </select>
                </div>}
            </div>

            <Modal 
                id={selected.id!} 
                title={'Remove Artist'} 
                ref={modalRef} 
                body={() => <ArtistConfirmRemoveBody id={selected.id!} first={selected.first} last={selected.last} />}
                footer={ConfirmActionFooter({label: 'Remove', onConfirm: handleConfirmRemove, onHide: hideModal})}
                onConfirm={handleConfirmRemove} 
                onHide={hideModal} />
        </div>);
}
import { CompositionForm } from "../../components/CompositionForm";
import { Spinner } from "../../components/Spinner";
import { useCompositionContext } from "../../hooks/composition/useCompositionContext";
import { CompositionContext } from "../../contexts/CompositionContext";

export const CompositionCreate = () => {
    const context = useCompositionContext();

    return(context.loading ? <Spinner /> :
        <CompositionContext.Provider value={context}>
            <CompositionForm />
        </CompositionContext.Provider>);
}
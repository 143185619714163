import React from "react";
import { formatCurrency } from "../utils/formatUtils";

export interface ModalProps {
    id: string | undefined;
    title: string;
    body: React.ComponentType;
    onHide: () => void;
    onConfirm: () => void;
    footer: JSX.Element;
}

export const Modal = React.forwardRef<HTMLDivElement, ModalProps>(({title, body: Body, footer: Footer, onHide, onConfirm}: ModalProps, ref) => {
    return(
        <div className="modal fade" ref={ref}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button type="button" className="btn-close" onClick={onHide}></button>
                    </div>
                    <div className="modal-body my-2">
                        <Body /> {/* as React.ComponentType */}
                    </div>
                    <div className="modal-footer">
                        {Footer} {/* as JSX.Element */}
                    </div>
                </div>
            </div>
        </div>);
});

export const OrderConfirmRemoveBody = ({id, first, last, total}: {id: string; first: string; last: string; total: number}) => {
    return(<div className="d-flex flex-column">
        <span>Removing order: {id}</span>
        <span>Customer: {first} {last}</span>
        <span>Total: {formatCurrency(total)}</span>
    </div>);
}

export const ArtistConfirmRemoveBody = ({id, first, last}: {id: string; first: string; last: string}) => {
    return(<div className="d-flex flex-column">
        <span>Removing artist: {id}</span>
        <span>Name: {first} {last}</span>
    </div>);
}

export const CompositionConfirmRemoveBody = ({id, title, price}: {id: string; title: string; price: number}) => {
    return(<div className="d-flex flex-column">
        <span>Removing composition: {id}</span>
        <span>Title: {title}</span>
        <span>Price: {formatCurrency(price)}</span>
    </div>);
}

export const CompositionLocationConfirmRemoveBody = ({name}: {name: string}) => {
    return(<div className="d-flex flex-column">
        <span>{name}</span>
    </div>);
}

export const ClientConfirmRemoveBody = ({id, brand, name}: {id: string; brand: string; name: string}) => {
    return(<div className="d-flex flex-column">
        <span>Removing client: {id}</span>
        <span>Brand: {brand}</span>
        <span>Name: {name}</span>
    </div>);
}

export const UserConfirmRemoveBody = ({id, first, last, role, isActive}: {id: string; first: string; last: string; role: string; isActive: string}) => {
    return(<div className="d-flex flex-column">
        <span>Removing user: {id}</span>
        <span>Name: {first} {last}</span>
        <span>Role: {role}</span>
        <span>Active: {isActive}</span>
    </div>);
}

export const GuestConfirmRemoveBody = ({id, first, last, subscribed}: {id: string | undefined; first: string; last: string; subscribed: boolean}) => {
    return(<div className="d-flex flex-column">
        <span>Removing guest: {id}</span>
        <span>Name: {first} {last}</span>
        <span>Subscribed: {subscribed ? 'True' : 'False'}</span>
    </div>);
}

export const InvoiceConfirmRemoveBody = ({id}: {id: string}) => {
    return(<div className="d-flex flex-column">
        <span>Removing invoice: {id}</span>
    </div>);
}

export const ConfirmActionFooter = ({label, onHide, onConfirm}: {label: string; onHide: () => void; onConfirm: () => void;}) => {
    return(<>
        <button type="button" className="btn btn-secondary btn-sm" onClick={onHide}>Cancel</button>
        <button type="button" className="btn btn-danger btn-sm" onClick={onConfirm}>{label}</button>
    </>);
}
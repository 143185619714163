import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../shared/BaseState";
import { ListQuery } from "../shared/Query";
import { artistService } from "./artistService";
import { Artist } from "../../types/Artist";
import { narrowError } from "../../utils/errorUtils";
import { toast } from "react-toastify";

interface ArtistState extends BaseState {
    artists: Artist[];
    count: number;
}

const initialState: ArtistState = {
    loading: false,
    success: false,
    error: undefined,
    artists: [],
    count: 0
}

// Get artists
export const getAll = createAsyncThunk(
    "artists/getAll",
    async (query: ListQuery, thunkAPI) => {
        try {
            const result = await artistService.getAll(query, thunkAPI.dispatch);
            return result;
        } catch (error) {
            const message = narrowError(error);
            toast.error(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const remove = createAsyncThunk(
    "artists/remove",
    async (id: string, thunkAPI) => {
        try {
            const removed = await artistService.remove(id, thunkAPI.dispatch);
            if (removed?.id) {
                toast.success(`Artist ${removed.id} removed`);
            } else {
                toast.error(`Could not remove artist`);
            }
            return removed;
        } catch (error) {
            const message = narrowError(error);
            toast.error(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const artistSlice = createSlice({
    name: "artist",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAll.pending, (state) => { state.loading = true; })
            .addCase(getAll.fulfilled, (state, action: PayloadAction<any>) => {
                const {artists, count} = action.payload;
                return {...state, loading: false, success: true, artists, count};
            })
            .addCase(getAll.rejected, (state, action) => {
                return {...state, loading: false, success: false, error: narrowError(action.payload)};
            })
            .addCase(remove.pending, (state) => { state.loading = true; })
            .addCase(remove.fulfilled, (state, action: PayloadAction<any>) =>{
                return {...state, loading: false, success: !!action.payload?.id, artists: state.artists.filter(c => c.id !== action.payload?.id), count: action.payload?.count};
            })
            .addCase(remove.rejected, (state, action) => {
                return {...state, loading: false, success: false, error: narrowError(action.payload)};
            })
    }
});

export default artistSlice.reducer;
import { useParams } from "react-router-dom";
import { useArtistContext } from "../../hooks/artist/useArtistContext";
import { Spinner } from "../../components/Spinner";
import { ArtistContext } from "../../contexts/ArtistContext";
import { ArtistForm } from "../../components/ArtistForm";

export const Artist = () => {
    const { id } = useParams<{id: string}>();
    const context = useArtistContext(id);

    return(context.loading ? <Spinner /> :
        <ArtistContext.Provider value={context}>
            <ArtistForm />
        </ArtistContext.Provider>);
}